import { DataAccessFactory } from "../../../../utilities/dataAccess/factory/DataAccessFactory";
import { ClientInfo } from "../../../../../models/index";

const DataAccess: any = DataAccessFactory.GetDataAccessService();

export const OrganizerService = {
    updateClientMobileNumber: (clientGuid: string, clientInfo: ClientInfo) => {
        return DataAccess.postJson(clientInfo, "api/OrganizerClient/UpdateClientMobileNumber/" + clientGuid);
    },
    getClientInfo: (clientId: string) => {
        return DataAccess.get("api/OrganizerClient/GetClient/" + clientId);
    },
    getSessionTimeOutMinutes: (clientId: string) => {
        return DataAccess.get("api/KeyVault/SessionTimeOutMinutes/" + clientId);
    },
    getRefreshToken: (clientId: string,refreshToken:string) => {
        return DataAccess.postJson('"'+refreshToken+'"',"api/Token/Mobile/Refresh/" + clientId);
    }
};
