import {
    LocalStorageStore,
    ILocalStore,
    IDateUtilities,
    DateUtilities,
    IFileUtilities,
    FileUtilities,
    IUtilities,
    Utilities
} from "../../../core/utilities";

export class UtilityFactory {
    public static create(): UtilityFactory {
        return new UtilityFactory();
    }
    public getLocalStore(): ILocalStore {
        return new LocalStorageStore();
    }
    public getDateUtility(): IDateUtilities {
        return new DateUtilities();
    }
    public getFileUtility(): IFileUtilities {
        return new FileUtilities();
    }
    public getUtility(): IUtilities {
        return new Utilities();
    }
}
