import { DataAccessFactory } from "../../../utilities/dataAccess/factory/DataAccessFactory";
import usersData from "./data/users.json";

const DataAccess: any = DataAccessFactory.GetDataAccessService();

const API_URL = "https://jsonplaceholder.typicode.com/users";

export const UserServices = {
    fetchData: () => {
        return Promise.resolve(usersData.users);
    },
};

// export default {
//     getUsersFromApi: async () => {
//         try {
//             const { data } = await axios.get();
//             return data;
//         } catch (err) {
//             console.log(err);
//         }
//     },
//     getUsersFromLocal: () => {}
// };
