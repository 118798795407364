import { headerInfoTypes } from "../../actionTypes";
import { IHeaderInfoViewModel, HeaderInfoViewModel } from "../../../models/header/headerInfoViewModel";
import { Action } from "redux";

const initialState: IHeaderInfoViewModel = HeaderInfoViewModel.createNullObject();

export const headerInfoMetaData = (state = initialState, incomingAction: Action) => {
    const action = incomingAction as any;
    const currentState = Object.assign({}, state);
    switch (action.type) {
        case headerInfoTypes.receiveHeaderDetails:
            let data = action.data;
            currentState.clientName = data.clientName;
            currentState.taxYear = data.taxYear;
            currentState.companyName = data.companyName;
            currentState.companyLogoPath = data.companyLogoPath;
            currentState.companyWhiteLogoPath = data.companyWhiteLogoPath;
            currentState.contactAddress = data.contactAddress;
            return { ...currentState };
        default:
            return state;
    }
};
