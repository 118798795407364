import {
  ControlBaseProps,
  ControlData,
} from "awesome-pdf-viewer/dist/Controls/ControlBase";
import { PageProperties } from "awesome-pdf-viewer/dist/layout/LayoutBase";
import * as Enumerable from "linq";
import {
  ClientType,
  FormGroupStatus,
  GroupType,
  IDocumentType,
} from "models/common/enums";
import { IClientInfo } from "models/esign/clientInfo";
import { ControlType, IControl } from "models/esign/controls";
import {
  ClientInfo,
  ELDocControlState,
  ELDocType,
  ElDocument,
  IElDocument,
  IElForm,
  OrganizerMetadata,
  SignerDocumentModel,
} from "models/organizer/organizer";
import * as React from "react";
import { EngagementLetterSignHelper } from "./EngagementLetterSignHelper";
import { SpouseInfoModal } from "./spouseInfo/spouseModal";
import { Viewer } from "./viewer";

export type EngagementLetterSignProps = {
  engagementDocument: SignerDocumentModel;
  clientInfo: ClientInfo;
  organizerMetadata: OrganizerMetadata;
  submitSignedDocument: any;
  skipEngagementSign: any;
  isPreviewMode(): boolean;
  handleSkipAndFinishForPreview(): boolean;
  requestSpouseInfo(): any;
  clientId: string;
  onUpdateSpouseEmail(clientInfo: IClientInfo, controlList: any): void;
  showSpouseInfo: boolean;
  toggleSpouseInfoModal(): void;
  handleSigningCompleted(): void;
  updateSignOnControlChange(allSigned: boolean): void;
  nextEnabled: boolean;
  controlStatus: ELDocControlState;
};

interface EngagementLetterSignState {
  finishEnabled: boolean;
  showSpouseInfo: boolean;
  isSkipped: boolean;
  engagementLetterStatus: FormGroupStatus;
  organizerStatus: FormGroupStatus;
  selectedPageNo: number;
  selectedEngagementDocument: SignerDocumentModel;
  isAdditionalDocument: boolean;
  controlList: any[];
  documents: IElDocument[];
  document: IElDocument;
  startNavigationOnDocumentLoad: boolean;
  clientType: ClientType;
  hideNavigationControl: boolean;
  signingCompleted: boolean;
}

export class EngagementLetterView extends React.Component<
  EngagementLetterSignProps,
  EngagementLetterSignState
> {
  private _viewerRef: any = null;
  constructor(props: EngagementLetterSignProps) {
    super(props);

    this.state = {
      finishEnabled: false,
      showSpouseInfo: false,
      isSkipped: false,
      engagementLetterStatus: FormGroupStatus.None,
      organizerStatus: FormGroupStatus.None,
      selectedPageNo: 1,
      selectedEngagementDocument: SignerDocumentModel.createNullObject(),
      isAdditionalDocument: false,
      controlList: [],
      documents: [],
      document: ElDocument.createNullObject(),
      startNavigationOnDocumentLoad: false,
      clientType: ClientType.Taxpayer,
      hideNavigationControl: false,
      signingCompleted: false,
    };
  }
  shouldComponentUpdate(
    nextProps: EngagementLetterSignProps,
    nextState: EngagementLetterSignState
  ) {
    if (
      nextProps.engagementDocument.documentGuid &&
      (nextProps.engagementDocument.formGroup.length > 0 ||
        nextProps.engagementDocument.additionalEsign.length > 0)
    ) {
      return true;
    }
    return false;
  }
  componentDidMount() {
    this.setState({
      startNavigationOnDocumentLoad: true,
    });
  }

  UNSAFE_componentWillReceiveProps(
    nextProps: EngagementLetterSignProps,
    nextState: EngagementLetterSignState
  ) {
    if (
      !nextProps.nextEnabled &&
      nextProps.engagementDocument.documentGuid &&
      (nextProps.engagementDocument.formGroup.length > 0 ||
        nextProps.engagementDocument.additionalEsign.length > 0)
    ) {
      this.setDocumentControls({ ...nextProps.engagementDocument });
    }
  }

  setDocumentControls = (document: SignerDocumentModel) => {
    let documents: IElDocument[] = [];
    const engagementDocument = document;
    const client: any =
      engagementDocument.organizerClients &&
      engagementDocument.organizerClients.find(
        (x: any) => x.clientGuid == this.props.clientId
      );
    if (client && engagementDocument.formGroup != undefined) {
      const engagementFormGroup = engagementDocument.formGroup.find(
        (x: any) => x.type == GroupType.Engagement
      );
      if (engagementFormGroup !== undefined) {
        const controlsFound =
          engagementFormGroup.forms.length <= 0
            ? true
            : engagementFormGroup.forms.some(
                (x: any) =>
                  x.formData &&
                  x.formData.controlList?.some(
                    (x: any) =>
                      x.controlRole == client.clientType &&
                      x.controlType != ControlType.Date
                  ) == true &&
                  x.formData.controlList.length > 0
              );

        let elForms: IElForm[] = [];
        if (controlsFound) {
          const formWithControls = engagementFormGroup.forms.filter(
            (x: any) =>
              x.formData &&
              x.formData.controlList.filter(
                (x: any) => x.controlRole == client.clientType
              ) &&
              x.formData.controlList.length > 0
          );
          formWithControls.forEach((form: any) => {
            {
              elForms.push({
                controls: form.formData.controlList.filter(
                  (x: any) => x.controlRole == client.clientType
                ),
                pageNo: form.individualFilePageNo,
                type: ELDocType.EL,
              });
            }
          });

          /* elForms.forEach((form: IElForm) => {
                        form.controls.forEach((control: IControl) => {
                            control.required = true
                        });
                    }); */
        }
        documents.push({
          id: engagementDocument.id,
          url: engagementDocument.documentUrl,
          forms: elForms,
          type: ELDocType.EL,
          documentType: IDocumentType.None,
          fileGuid: engagementDocument.fileGuid,
        });
      }
    }

    //Additional Esign
    if (engagementDocument.additionalEsign.length > 0) {
      const additionalDocs = engagementDocument.additionalEsign;
      additionalDocs.forEach((doc: any) => {
        let elForms: IElForm[] = [];
        const sortFormData = doc.documentControls?.formData?.sort(
          (a: any, b: any) => a.pageNo - b.pageNo
        );
        sortFormData?.forEach((form: any) => {
          elForms.push({
            controls: form.controlList.filter(
              (x: any) => x.controlRole == client.clientType
            ),
            pageNo: form.pageNo,
            type: ELDocType.Additional,
            fileGuid: doc.fileGuid,
          });
        });

        documents.push({
          id: doc.id,
          url: doc.sasURL,
          forms: elForms,
          type: ELDocType.Additional,
          fileName: doc.fileName,
          documentType: doc.documentType,
          fileGuid: doc.fileGuid,
        });
      });
    }

    //Organizer docs
    const organizerFormGroup = engagementDocument.formGroup.find(
      (x: any) => x.type == GroupType.OrganizerWithSign
    );
    if (organizerFormGroup != undefined) {
      const controlsFound =
        organizerFormGroup.forms.length <= 0
          ? true
          : organizerFormGroup.forms.some(
              (x: any) =>
                x.formData &&
                x.formData.controlList.some(
                  (x: any) =>
                    x.controlRole == client.clientType &&
                    x.controlType != ControlType.Date
                ) == true &&
                x.formData.controlList.length > 0
            );
      let elForms: IElForm[] = [];
      if (controlsFound) {
        const formWithControls = organizerFormGroup.forms.filter(
          (x: any) =>
            x.formData &&
            x.formData.controlList.filter(
              (x: any) => x.controlRole == client.clientType
            ) &&
            x.formData.controlList.length > 0
        );
        formWithControls.forEach((form: any) => {
          elForms.push({
            controls: form.formData.controlList.filter(
              (x: any) => x.controlRole == client.clientType
            ),
            pageNo: form.individualFilePageNo,
            type: ELDocType.OrganizerSignDocument,
          });
        });
      }

      documents.push({
        id: engagementDocument.id + 1,
        url: engagementDocument.organizerFormGroupUrl,
        forms: elForms,
        type: ELDocType.OrganizerSignDocument,
        documentType: IDocumentType.organizerWithSignatureDocument,
        fileGuid: engagementDocument.fileGuid,
      });
    }

    client &&
      this.setState({
        documents: documents,
        document: documents[0],
        clientType: client.clientType,
      });
  };
  handleNavigationFinish = () => {
    this.setControlValues();
    this.setDocument();
  };

  private updateControlData = (
    controlData: ControlData,
    controlProps: ControlBaseProps
  ) => {
    const { documents, document } = this.state;
    const signCompleted = EngagementLetterSignHelper.updateControlData(
      documents,
      controlData,
      controlProps
    );
    const updatedDocument = documents.filter((x) => x.id === document.id)[0];
    this.setState(
      {
        document: updatedDocument,
        signingCompleted: signCompleted,
      },
      () => {
        signCompleted && this.setControlValues(this.handleSigningCompleted());
      }
    );
    this.props.updateSignOnControlChange(signCompleted);
  };

  setControlValues = (callback?: any) => {
    const controls = this._viewerRef._controlList.filter((x: any) => x != null);
    let tempcontrols = [...this.state.controlList];

    // Update existing signature controls data with the latest signature data
    var signatureControl = controls.find(
      (c: any) =>
        c.props.data["controlType"] === 1 && c.state.signatureData !== undefined
    );
    if (signatureControl) {
      const controlData = signatureControl.getControlData();
      tempcontrols.forEach((control: any) => {
        if (
          control.props.data["controlType"] === 1 &&
          control.state.signatureData !== undefined
        ) {
          control.state = { ...control.state, signatureData: controlData };
        }
      });
    }

    controls.forEach((control: any) => {
      const index = tempcontrols?.findIndex(
        (x: any) => x?.props?.id == control.props.id
      );
      if (index < 0) {
        tempcontrols.push(control);
      } else {
        tempcontrols[index] = control;
      }
    });
    this.setState(
      {
        controlList: tempcontrols,
      },
      () => {
        callback && callback();
      }
    );
  };

  clearAllControls = () => {
    this._viewerRef._controlList
      ?.filter((x: any) => x != null)
      .forEach((control: any) => {
        this._viewerRef._controlLayer.removeControl(control.props.id);
      });
  };
  onPageChanging = (pageProperties: PageProperties) => {
    this.setState({
      selectedPageNo: pageProperties.page > 0 ? pageProperties.page : 1,
    });
  };
  documentHasControls = (document: ElDocument) => {
    return (
      document.forms.filter(
        (y) =>
          y.controls.some(
            (x: any) =>
              x.controlRole == this.state.clientType &&
              x.controlType != ControlType.Date &&
              x.controlData == null
          ) == true
      ).length > 0
    );
  };

  private setDocument = () => {
    if (this.state.document.id > 0) {
      const documents = this.state.documents;
      const filteredDocuments = documents.filter((x) =>
        x.forms.some((x) => x.controls.length > 0)
      );

      const nextDocumentIndex =
        filteredDocuments.indexOf(this.state.document) + 1;

      const nextDocument: IElDocument | undefined =
        Enumerable.from(filteredDocuments).elementAtOrDefault(
          nextDocumentIndex
        );

      if (nextDocument == undefined) {
        this.navigateToFirstUnsignedDocument();
        return;
      }

      if (documents.length == nextDocumentIndex + 1) {
        this.clearAllControls();
        this.setNextDocument(nextDocument);
      } else {
        this.clearAllControls();
        this.setNextDocument(nextDocument);
      }
    } else {
      this.clearAllControls();
      this.setFirstDocument();
    }
  };

  navigateToFirstUnsignedDocument = () => {
    const unsignedDocuments = this.state.documents.filter(
      (x) => !x.signCompleted
    );
    if (unsignedDocuments.length > 0) {
      if (unsignedDocuments?.length === 1) {
        if (unsignedDocuments[0].id !== this.state.document.id)
          this.clearAllControls();
        this.setNextDocument(unsignedDocuments[0]);
      } else {
        this.clearAllControls();
        this.setNextDocument(unsignedDocuments[0]);
      }
    } else return;
  };

  private setFirstDocument = () => {
    this.setState({ document: this.state.documents[0], selectedPageNo: 1 });
  };

  private setNextDocument = (nextDocument: IElDocument) => {
    if (nextDocument) {
      this.setState({
        document: nextDocument,
        startNavigationOnDocumentLoad: true,
        selectedPageNo: 1,
      });
    }
  };

  handleSigningCompleted = () => {
    const documents = [...this.state.documents];
    const finishEnabled =
      documents.filter((x) => x.signCompleted).length === documents.length;
    finishEnabled && this.props.handleSigningCompleted();
  };

  private isLastDocument = (document: IElDocument) => {
    if (document) {
      const filteredDocuments = this.state.documents.filter((x) =>
        x.forms.some((x) => x.controls.length > 0)
      );
      // set next document using index
      const nextDocumentIndex = filteredDocuments.indexOf(document) + 1;

      const nextDocument: IElDocument | undefined =
        Enumerable.from(filteredDocuments).elementAtOrDefault(
          nextDocumentIndex
        );

      if (!nextDocument) {
        return true;
      }
      return false;
    }
    return true;
  };

  checkControlFound = () => {
    let controlpresent =
      this.state.documents.filter((y) =>
        y.forms.some(
          (x) =>
            x.controls.some(
              (x: any) =>
                x.controlRole == this.state.clientType &&
                x.controlType != ControlType.Date &&
                x.controlData == null
            ) == true
        )
      ).length > 0;

    let requiredControlPresent =
      this.state.documents.filter((y) =>
        y.forms.some(
          (x) =>
            x.controls.some(
              (x: any) =>
                x.controlRole == this.state.clientType &&
                x.controlType != ControlType.Date &&
                x.required == true
            ) == true
        )
      ).length > 0;

    return controlpresent && requiredControlPresent;
  };

  checkAllDocumentsSigned = () => {
    return (
      this.state.documents.filter((x) => x.signCompleted).length ===
      this.state.documents.length
    );
  };

  setNavigationStatus = (show: boolean) => {
    this.setState({
      hideNavigationControl: show,
    });
  };
  handleUpdateSpouseEmail = (clientInfo: IClientInfo): any => {
    this.props.onUpdateSpouseEmail(clientInfo, this.getDocumentControlInfo());
  };
  getDocumentControlInfo = (): any => {
    return this.state.controlList;
  };
  finishEnabled = () => {
    return (
      this.props.isPreviewMode() ||
      this.checkAllDocumentsSigned() ||
      !this.checkControlFound()
    );
  };
  public render() {
    const { engagementDocument, organizerMetadata, controlStatus } = this.props;

    const { document, startNavigationOnDocumentLoad, hideNavigationControl } =
      this.state;

    let controlsFound: boolean = this.checkControlFound();

    const isPreview = this.props.isPreviewMode();
    const finishTarget: string = this.isLastDocument(document)
      ? "awesome-multi-steps-btn-next-container"
      : "";
    const finishEnabled = this.finishEnabled();
    finishEnabled && this.props.handleSigningCompleted();

    return (
      <div>
        <Viewer
          ref={(ref: any) => {
            this._viewerRef = ref;
          }}
          clientId={this.props.clientId}
          controlsFound={controlsFound}
          documentUrl={document?.url}
          onNavigationFinish={this.handleNavigationFinish}
          startNavigationOnDocumentLoad={startNavigationOnDocumentLoad}
          hideNavigationControl={hideNavigationControl}
          elForms={document?.forms}
          finishTarget={finishTarget}
          onPageChanging={this.onPageChanging}
          uploadMethod={engagementDocument?.data?.uploadMethod}
          setNavigationStatus={this.setNavigationStatus}
          pageNo={this.state.selectedPageNo}
          EngagementFiledocuments={engagementDocument}
          onAddControlData={this.updateControlData}
          document={this.state.document}
          signCompleted={this.state.signingCompleted}
          isReadMode={isPreview}
          controlStatus={controlStatus}
          organizerMetadata={organizerMetadata}
        />
        <SpouseInfoModal
          clientId={this.props.clientId}
          clientInfo={this.props.clientInfo}
          onUpdateSpouseEmail={this.handleUpdateSpouseEmail}
          onRequestSpouseInfo={this.props.requestSpouseInfo}
          show={this.props.showSpouseInfo}
          onClose={this.props.toggleSpouseInfoModal}
        />
      </div>
    );
  }
}
