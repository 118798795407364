import { SignatureMode } from "awesome-pdf-viewer/dist/Controls/ControlBase";
import { IControlData } from "../esign/controls";


export class SignatureData implements IControlData {
    name: string;
    uri: string;
    signatureMode: SignatureMode;
    uniqueId?: string;

    constructor(name: string,
        uri: string, signatureMode: SignatureMode, uniqueId?: string) {
        this.name = name;
        this.uri = uri;
        this.signatureMode = signatureMode;
        this.uniqueId = uniqueId
    }

    public static create(name: string,
        uri: string, signatureMode: SignatureMode, uniqueId?: string) {

        return new SignatureData(name,
            uri, signatureMode, uniqueId);

    }


    public static createNullObject() {

        return new SignatureData("",
            "", SignatureMode.None, "");

    }

}