import React from "react"

const TrashIcon: React.FC<{ height: string, width: string, color: any }> = ({
    color, height, width
}) => (
    <svg width={width} height={height} viewBox="0 0 24 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>icon - delete</title>
        <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-132.000000, -6937.000000)" fill={color} fill-rule="nonzero" id="icon---delete">
                <g transform="translate(132.000000, 6937.000000)" id="Group">
                    <path d="M14.4,3.93939394 C14.4,2.63399362 13.3254834,1.57575758 12,1.57575758 C10.6745166,1.57575758 9.6,2.63399362 9.6,3.93939394 L8,3.93939394 C8,1.76372674 9.790861,3.49888468e-16 12,3.49888468e-16 C14.209139,3.49888468e-16 16,1.76372674 16,3.93939394 L23.2,3.93939394 C23.6418278,3.93939394 24,4.29213929 24,4.72727273 C24,5.16240617 23.6418278,5.51515152 23.2,5.51515152 L22.3136,5.51515152 L20.24,23.2109091 C20.0531131,24.8007716 18.6859037,26 17.0608,26 L6.9392,26 C5.31409631,26 3.94688691,24.8007716 3.76,23.2109091 L1.6848,5.51515152 L0.8,5.51515152 C0.413612303,5.51527838 0.0823497679,5.24340887 0.0128,4.86909091 L0,4.72727273 C0,4.29213929 0.3581722,3.93939394 0.8,3.93939394 L14.4,3.93939394 Z M20.7008,5.51515152 L3.2976,5.51515152 L5.3488,23.029697 C5.44227843,23.8249271 6.12634284,24.4247504 6.9392,24.4242427 L17.0608,24.4242427 C17.8730465,24.42395 18.5561917,23.8243295 18.6496,23.029697 L20.7008,5.51515152 Z M9.6,9.45454545 C9.992,9.45454545 10.32,9.69878788 10.3872,10.0202424 L10.4,10.1447273 L10.4,19.7962424 C10.4,20.176 10.0416,20.4848485 9.6,20.4848485 C9.208,20.4848485 8.88,20.2406061 8.8128,19.9191515 L8.8,19.7946667 L8.8,10.146303 C8.8,9.7649697 9.1584,9.45612121 9.6,9.45612121 L9.6,9.45454545 Z M14.4,9.45454545 C14.792,9.45454545 15.12,9.69878788 15.1872,10.0202424 L15.2,10.1447273 L15.2,19.7962424 C15.2,20.176 14.8416,20.4848485 14.4,20.4848485 C14.008,20.4848485 13.68,20.2406061 13.6128,19.9191515 L13.6,19.7946667 L13.6,10.146303 C13.6,9.7649697 13.9584,9.45612121 14.4,9.45612121 L14.4,9.45454545 Z" id="Shape"></path>
                </g>
            </g>
        </g>
    </svg>
)

export { TrashIcon }