import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { routes } from "./common/constants/routes";
import "./App.css";
import { Loader } from "./components/common";
import { useSelector } from "react-redux";
import { initializeAppInsights, TelemetryLogger } from "./components/Logger/AppInsights";

export const Logger = TelemetryLogger.getInstance();

const RouteWrapper = ({ component: Component, layout: Layout, ...rest }: any) => {
    rest.computedMatch.params.id && initializeAppInsights(rest.computedMatch.params.id);
    return (
        <Route
            {...rest}
            render={(props) =>
                Layout ? (
                    <Layout {...props}>
                        <Component {...props} />
                    </Layout>
                ) : (
                    <Component {...props} />
                )
            }
        />
    );
};

const App: React.FC<{}> = () => {
    const loader = useSelector((state: { loader: boolean }) => state.loader);
    return (
        <Router>
            {loader && <Loader />}
            <Switch>
                {routes.map((route: []) => (
                    <RouteWrapper key={"key"} {...route} />
                ))}
            </Switch>
        </Router>
    );
};

export default App;
