import { injectable } from "inversify";
import { IBrandingSettings } from "../../../models";
import "reflect-metadata";
import { MessageBox } from "../../../components/common/notification/notification";
import { ValidationContants } from "../../../common";

export interface IUtilities {
    formateFax(fax: string): string;
    isValidEmailAddress(emailAddress: string): boolean;
    getUrlPathLastComponent(path: string): string;
    increaseBrightness(hex: string, percent: number): string;
    applyBrandingSettings(setting: IBrandingSettings): void;
    formatCurrencyText(Value: number): string;
    validateTenDigitphoneNumber(value: string): boolean;
    phoneNumberDisplay(phoneNumber: string): string;
    validateCountryCode(countryCode: string): boolean;
    validatePhone(phoneNoId: string): boolean;
}

@injectable()
export class Utilities implements IUtilities {
    formateFax(fax: string): string {
        if (fax == undefined || (fax != undefined && fax.length != 10)) {
            console.log("Invalid Fax value");
            return "";
        }
        return "(" + fax.slice(0, 3) + ") " + fax.slice(3, 6) + "-" + fax.slice(6);
    }

    isValidEmailAddress(emailAddress: string): boolean {
        var pattern = new RegExp(
            /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i
        );
        return pattern.test(emailAddress);
    }

    getUrlPathLastComponent(path: string): string {
        var pathComponents = path.split("/");
        return pathComponents[pathComponents.length - 1];
    }

    increaseBrightness(hex: string, percent: number): string {
        // strip the leading # if it's there
        hex = hex.replace(/^\s*#|\s*$/g, "");

        // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
        if (hex.length == 3) {
            hex = hex.replace(/(.)/g, "$1$1");
        }

        var r = parseInt(hex.substr(0, 2), 16),
            g = parseInt(hex.substr(2, 2), 16),
            b = parseInt(hex.substr(4, 2), 16);

        return (
            "#" +
            (0 | ((1 << 8) + r + ((256 - r) * percent) / 100)).toString(16).substr(1) +
            (0 | ((1 << 8) + g + ((256 - g) * percent) / 100)).toString(16).substr(1) +
            (0 | ((1 << 8) + b + ((256 - b) * percent) / 100)).toString(16).substr(1)
        );
    }

    applyBrandingSettings(setting: IBrandingSettings): void {
        var html = document.getElementsByTagName("html")[0];
        let style: string = "";
        style += "--headerBgColor:" + setting.layoutSetting.bgColorCode + ";";
        style += "--headerForeColor:" + setting.layoutSetting.foreColorCode + ";";
        style += "--bottonBgColor:" + setting.layoutSetting.bgColorCode + ";";
        style += "--bottonBorderColor:" + this.increaseBrightness(setting.layoutSetting.bgColorCode, 5) + ";";
        html.style.cssText = style;
    }

    formatCurrencyText(Value: number): string {
        var InputVal = Math.floor(Value);
        var formatted = "";

        if (Value % 1 === 0) {
            formatted = InputVal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
            return Value.toString();
        }

        return formatted;
    }
    validateTenDigitphoneNumber(value: string): boolean {
        var pattern = new RegExp(/^[0-9\-\s)\(]{0,14}$/);
        return pattern.test(value);
    }

    phoneNumberDisplay(phoneNumber: string): string {
        return phoneNumber && phoneNumber.length == 10
            ? "(" +
                  phoneNumber.substring(0, 3) +
                  ") " +
                  phoneNumber.substring(3, 6) +
                  "-" +
                  phoneNumber.substring(6, 10)
            : phoneNumber;
    }

    validateCountryCode(countryCode: string): boolean {
        if (countryCode.trim() === "") {
            MessageBox.Warning(ValidationContants.CountryCodeWarning);
            return false;
        } else return true;
    }

    validatePhone(phoneNoId: string): boolean {
        var phoneNo = phoneNoId.trim();
        if (phoneNo.trim() == "") {
            MessageBox.Warning(ValidationContants.PhoneNumberWarning);
            return false;
        } else if (phoneNo.trim().length < 10 || /^[0-9]{1,10}$/.test(phoneNo) == false) {
            MessageBox.Warning(ValidationContants.PhoneNumberLengthWarning);
            return false;
        } else return true;
    }
}
