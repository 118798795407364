import { otpTypes, errorTypes } from "../../actionTypes";
import { loaderAction } from "./../loaderAction";
import { ServiceFactory } from "../../../core/services/dataAccess/factory/ServiceFactory";
import { MessageBox } from "../../../components/common/notification/notification";
import { OTPPageConstants, DisplayError, LocalStorageConstant } from "../../../common/constants/constant";
import { AxiosResponse } from "axios";
import { ILocalStore } from "../../../core/utilities";
import { container, TYPES } from "../../../core/startup";
import { ErrorStatus } from "../../../models";

const LocalStorageService = container.get<ILocalStore>(TYPES.ILocalStore);

export const OTPAction = {
    validateLink: (clientId: string, errorCallback: (errorState: any) => void) => async (dispatch: any) => {
        dispatch(loaderAction.start());
        await ServiceFactory.GetOTPService()
            ?.validateLink(clientId)
            .then(function (response: AxiosResponse<any>) {
                let result = response.data;
                if (result.isSuccess) {
                    dispatch({ type: otpTypes.setValidOtp, payload: result.data });
                } else {
                    let errorDesc = result.errorDescription;
                    let errorCode = result.errorCode;
                    if (errorCode && DisplayError.includes(errorCode)) {
                        dispatch({
                            type: errorTypes.setErrorMsg,
                            payload: errorDesc
                        });
                        errorCallback && errorCallback(result);
                    } else {
                        dispatch({ type: otpTypes.setInvalidOtp });
                    }
                }
                dispatch(loaderAction.stop());
            })
            .catch(function (error: any) {
                console.log(error);
                dispatch(loaderAction.stop());
            });
    },
    generateOTP: (clientId: string) => async (dispatch: any) => {
        await ServiceFactory.GetOTPService()
            .generateOTP(clientId)
            .then(function (response: AxiosResponse<any>) {
                if (response.data) {
                    MessageBox.Success(OTPPageConstants.SuccessMessage.OTPGenerateSuccess);
                } else {
                    MessageBox.Error(OTPPageConstants.ErrorMessage.OTPGenerateFailed);
                }
                dispatch(loaderAction.stop());
            })
            .catch(function (error: any) {
                console.log(error);
            });
    },
    generateMobileOTP: (clientId: string) => async (dispatch: any) => {
        await ServiceFactory.GetOTPService()
            .generateMobileOTP(clientId)
            .then(function (response: AxiosResponse<any>) {
                if (response.data) {
                    MessageBox.Success(OTPPageConstants.SuccessMessage.mobileOTPGenerateSuccess);
                } else {
                    MessageBox.Error(OTPPageConstants.ErrorMessage.OTPGenerateFailed);
                }
            })
            .catch(function (error: any) {
                console.log(error);
            });
    },
    verifyOTP:
        (otp: string, clientId: string, callback?: (guid: string) => void, errorCallback?: (errorState: any) => void) =>
        async (dispatch: any) => {
            await ServiceFactory.GetOTPService()
                .verifyOTP(otp, clientId)
                .then(function (response: AxiosResponse<any>) {
                    let result = response.data;
                    if (result.isSuccess) {
                        LocalStorageService.setItemByUniqueKey(
                            result.data.clientId.toString(),
                            LocalStorageConstant.EncryptedClientId,
                            clientId
                        );
                        LocalStorageService.setItemByUniqueKey(
                            result.data.clientId.toString(),
                            LocalStorageConstant.Token,
                            result.data.token
                        );
                        LocalStorageService.setItemByUniqueKey(
                            result.data.clientId.toString(),
                            LocalStorageConstant.RefreshToken,
                            result.data.refreshToken
                        );
                        if (callback !== undefined) {
                            callback(result.data.clientId);
                        }
                    } else {
                        let errorDesc = result.errorDescription;
                        let errorCode = result.errorCode;

                        if (errorCode && DisplayError.includes(errorCode)) {
                            dispatch({
                                type: errorTypes.setErrorMsg,
                                payload: errorDesc
                            });
                            errorCallback && errorCallback(result);
                        } else {
                            MessageBox.Error(errorDesc);
                        }
                    }
                })
                .catch(function (error: any) {
                    console.log(error);
                });
        },

    verifyMobileOTP:
        (otp: string, clientId: string, callback?: (guid: string) => void, errorCallback?: () => void) =>
        async (dispatch: any) => {
            await ServiceFactory.GetOTPService()
                .verifyMobileOTP(otp, clientId)
                .then(function (response: AxiosResponse<any>) {
                    let result = response.data;
                    if (result.isSuccess) {
                        LocalStorageService.setItemByUniqueKey(
                            result.data.clientId.toString(),
                            LocalStorageConstant.EncryptedClientId,
                            clientId
                        );
                        LocalStorageService.setItemByUniqueKey(
                            result.data.clientId.toString(),
                            LocalStorageConstant.Token,
                            result.data.token
                        );
                        LocalStorageService.setItemByUniqueKey(
                            result.data.clientId.toString(),
                            LocalStorageConstant.RefreshToken,
                            result.data.refreshToken
                        );
                        LocalStorageService.setItem(LocalStorageConstant.Token, result.data.token);
                        if (callback !== undefined) {
                            callback(result.data.clientId);
                        }
                    } else {
                        let errorDesc = result.errorDescription;
                        let errorCode = result.errorCode;

                        if (errorCode && DisplayError.includes(errorCode)) {
                            dispatch({
                                type: errorTypes.setErrorMsg,
                                payload: errorDesc
                            });
                            errorCallback && errorCallback();
                        } else {
                            MessageBox.Error(errorDesc);
                        }
                    }
                })
                .catch(function (error: any) {
                    console.log(error);
                });
        }
};
