import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { ErrorStatus, IErrorState } from "../../../models";
import { MessageBox, Toaster } from "../../common";
import { OrganizerStatusChangedWarning } from "common";

export const ErrorPage: React.FC<RouteComponentProps<{ errorStatus?: string; }>> = ({ match: { params: { errorStatus } } }) => {
    const { message } = useSelector((state: { errorReducer: IErrorState }) => state.errorReducer);

    useEffect(() => {
        if (errorStatus === ErrorStatus.ClosedByFirm.toString() || errorStatus === ErrorStatus.OrganizerDeleted.toString()) {
            MessageBox.Error(OrganizerStatusChangedWarning);
        }
    }, [errorStatus]);

    const getErrorMessage = (): string => {
        const commonMessage = "This link is no longer valid. Please contact the company that prepared your Organizer.";
        const closedMessage = "Your Organizer has been closed to prevent any further use. If you have any questions, please contact your CPA.";
        switch (errorStatus) {
            case ErrorStatus.ClosedByFirm.toString():
                return closedMessage;
            case ErrorStatus.OrganizerDeleted.toString():
                return commonMessage;
            case ErrorStatus.InvalidLink.toString():
            case ErrorStatus.None.toString():
            case ErrorStatus.OrganizerWithOldCustomQuestion.toString():
            default:
                return commonMessage;
        }
    }

    return (
        <>
            <Toaster />
            <div className="ErrorContainer" data-testid="errorPageWrapper">
                <div className="Errorheader"></div>
                <div className="ErrorPageContent" data-testid="errorPageContent">
                    <div className="MessageContainer" data-testid="errorMessageContainer">
                        <p className="ErrorMsgPara" data-testid="errorMsg">
                            {message === ""
                                ? getErrorMessage()
                                : message}
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};
