export interface IOTPState {
    loading: boolean;
    error: any;
    mobileNo: string;
    countryCode: string;
}

export class OTPState implements IOTPState {
    loading: boolean;
    error: any;
    mobileNo: string;
    countryCode: string;

    constructor(loading: boolean, error: any, mobileNo: string, countryCode: string) {
        this.loading = loading;
        this.error = error;
        this.mobileNo = mobileNo;
        this.countryCode = countryCode;
    }

    public static createNullObject(): IOTPState {
        return new OTPState(false, "", "", "");
    }
}
