import * as Moment from 'moment';
import { IOrganizerMetadata } from '../../../models/organizer/organizer';

export function GetFormatedDateTime(date: Date) {
    return Moment.utc(date).local().format('MM/DD/YYYY hh:mm A');
}

export function CountryCodeOptions() {
    var countryCodeOption = [];
    countryCodeOption.push({ value: '+1', label: 'US(+1)' });
    countryCodeOption.push({ value: '+91', label: 'India(+91)' });
    return countryCodeOption;
}

export function EntityTypeOptions() {
    var entityTypeOption = [];
    entityTypeOption.push({ value: '1', label: 'Individual' });
    entityTypeOption.push({ value: '2', label: 'Partnership' });
    entityTypeOption.push({ value: '3', label: 'Corporation' });
    entityTypeOption.push({ value: '4', label: 'Estate' });
    entityTypeOption.push({ value: '5', label: 'Trust' });
    entityTypeOption.push({ value: '6', label: 'DisregardedEntity' });
    entityTypeOption.push({ value: '7', label: 'ExemptOrganization' });
    entityTypeOption.push({ value: '8', label: 'ForeignGovernment' });
    entityTypeOption.push({ value: '9', label: 'Nominee' });
    return entityTypeOption;
}

export function k1StatusOptions() {
    var k1StatusOption = [];
    k1StatusOption.push({ value: '1', label: 'Updated' });
    k1StatusOption.push({ value: '2', label: 'Confirmed' });
    k1StatusOption.push({ value: '3', label: 'Pending' });
    return k1StatusOption;
}

export function IsPreviewMode(organizerMetaDataInput: IOrganizerMetadata) {
    return organizerMetaDataInput.isPreview === true;
}

export function getFileSize(fileSizeInBytes: number) {
    const file = parseInt((fileSizeInBytes / 1024).toString(), 10);
    return file < 1024 ? (file + " KB").toString() : (parseInt((file / 1024).toString(), 10) + " MB").toString();
}