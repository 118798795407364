import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { IClientInfo } from "../../models/index";
import { ClientType } from "../../models/index";
import { PhoneNumberComponent } from "../common/PhoneNumberComponent/PhoneNumberComponent";
import { EditableDropdown } from "../common/Select/EditableDropdown";
import { CountryCodes } from "../../common";
import { container, TYPES } from "../../core/startup";
import { IUtilities } from "../../core/utilities";

const Utilities = container.get<IUtilities>(TYPES.IUtilities);

export interface MyAccountProps {
    show: boolean;
    closeMyAccount(): void;
    taxPayer?: IClientInfo;
    onChangeNumberClick(): void;
    spouse?: IClientInfo;
    onSaveButtonClick(email: string, number: string, countryCode: string): void;
}

export const MyAccount: React.FC<MyAccountProps> = (props) => {
    const [maskedPhoneNumber, setMaskedPhoneNumber] = useState("");
    const [mobileNumberString, setMobileNumberString] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");

    useEffect(() => {
        props.taxPayer !== undefined &&
            props.taxPayer.mobileNumber !== undefined &&
            setMaskedPhoneNumber(phoneNumbermasking(props.taxPayer.mobileNumber));
        if (props.spouse) {
            setEmailAddress(props.spouse.email);
            setCountryCode(props.spouse.countryCode);
            setPhoneNumber(props.spouse.mobileNumber);
        }
    }, [props]);

    useEffect(() => {
        const mobileString =
            maskedPhoneNumber && maskedPhoneNumber.length > 5 ? "Change Number" : " Enter Mobile Number";
        setMobileNumberString(mobileString);
    }, [maskedPhoneNumber]);

    const phoneNumbermasking = (number: string) => {
        let n1, n2, n3, maskedNumber;
        if (number && number.length === 10) {
            n1 = number.substring(0, 3);
            n2 = number.substring(3, 6);
            n3 = number.substring(6, 10);
            maskedNumber = "(" + n1 + ")" + " " + n2 + "-" + n3;
        } else {
            maskedNumber = number;
        }
        return maskedNumber;
    };

    let onSpouseMailChange = (e: any) => {
        setEmailAddress(e.target.value);
    };

    let onChangeCountryCodeForSpouse = (value: string) => {
        setCountryCode(value);
    };

    let onChangePhoneNumber = (value: string) => {
        setPhoneNumber(value);
    };

    let onHide = () => {
        if (props.spouse) {
            setEmailAddress(props.spouse.email);
            setCountryCode(props.spouse.countryCode);
            setPhoneNumber(props.spouse.mobileNumber);
        }
        props.closeMyAccount();
    };

    return (
        <>
            <Modal
                show={props.show}
                className="myaccount"
                onHide={props.closeMyAccount}
                data-test-auto="689A5D12-FAFD-44B8-8C11-373C29A7C1BF"
            >
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: "16px" }}>
                        <span className="fa fa-user ddl-icon" style={{ padding: "6px" }}></span>
                        My Account
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-12" style={{ display: "inline" }}>
                            <div>
                                <span className="myaccount modal-title">Mobile Login Number</span>
                            </div>
                            <div className="myaccount-text">
                                <span style={{ fontSize: "14px" }}>
                                    An access code will be sent to the following mobile device number every time you
                                    login
                                </span>
                            </div>

                            <div
                                className="myaccount-text"
                                style={{ display: "inline-flex" }}
                                data-test-auto="FB4FBF72-5EA4-45F9-A6EA-F3608FDEE04B"
                            >
                                <span style={{ paddingRight: "10px" }}>
                                    <b>Mobile Device Number: </b>
                                </span>
                                {maskedPhoneNumber && maskedPhoneNumber.length > 5 && (
                                    <span className="myaccount-countrycode">
                                        {props.taxPayer && props.taxPayer.countryCode}
                                    </span>
                                )}
                                {maskedPhoneNumber && maskedPhoneNumber.length > 5 && (
                                    <span className="myaccount-changenumber">{maskedPhoneNumber}</span>
                                )}

                                <a
                                    href="javascript:void(0);"
                                    className="tab"
                                    style={{ color: "#88c656" }}
                                    data-testid="mobileNumberString"
                                    data-test-auto="D0A438D7-E156-4621-840A-29B0A0D22745"
                                    onClick={() => {
                                        props.onChangeNumberClick();
                                    }}
                                >
                                    {mobileNumberString}
                                </a>
                            </div>

                            <br />
                            {props.spouse && (
                                <div>
                                    <div data-test-auto="8F714F79-9673-44C4-9DAB-084BEC4566AC">
                                        <span className="myaccount modal-title" data-testid="clientType">
                                            {props.spouse.clientType === ClientType.Spouse
                                                ? "Spouse Info"
                                                : "Taxpayer Info"}
                                        </span>
                                    </div>

                                    <div
                                        className="row myaccount-text"
                                        data-test-auto="B305427D-D94A-47D6-AE22-5B71ACA21532"
                                    >
                                        <div className="col-sm-12 col-md-12 col-xl-12">
                                            <span style={{ paddingRight: "5px" }}>
                                                <b>
                                                    {props.spouse.clientType === ClientType.Spouse
                                                        ? "Spouse Name:"
                                                        : "Taxpayer Name:"}
                                                </b>
                                            </span>
                                            <span data-testid="clientName" style={{ fontSize: "12px" }}>{props.spouse.name}</span>
                                        </div>
                                    </div>

                                    <div className="second-signerinfo">
                                        <div className="emailSection">
                                            <div
                                                className="myaccount-text"
                                                style={{ paddingBottom: "0px" }}
                                                data-test-auto="28064FB4-EC12-4944-9754-1396823CE426"
                                            >
                                                <span>
                                                    <b>
                                                        {props.spouse.clientType === ClientType.Spouse
                                                            ? "Spouse Email Address"
                                                            : "Taxpayer Email Address"}
                                                    </b>
                                                </span>
                                            </div>
                                            <div className="myaccount-text">
                                                <input
                                                    type="text"
                                                    className="form-control custom-input"
                                                    data-testid="clientEmail"
                                                    data-test-auto="CDAB9814-31A5-4543-8B0B-DE8606825993"
                                                    value={emailAddress}
                                                    onChange={(e) => onSpouseMailChange(e)}
                                                    style={{ fontSize: "15px", height: "38px" }}
                                                />
                                            </div>
                                        </div>
                                        <div className="mobileSection">
                                            <div
                                                className="myaccount-text"
                                                style={{ paddingBottom: "0px" }}
                                                data-test-auto="DA080EA9-D508-423E-9E48-99F15673059C"
                                            >
                                                <span>
                                                    <b>
                                                        {props.spouse.clientType === ClientType.Spouse
                                                            ? "Spouse Mobile Number"
                                                            : "Taxpayer Mobile Number"}
                                                    </b>
                                                </span>
                                            </div>

                                            <div className="myaccount-text phonenumber">
                                                <div className="phoneNumberSelect">
                                                    <EditableDropdown
                                                        id="ddlCountryCode"
                                                        options={CountryCodes()}
                                                        onChange={onChangeCountryCodeForSpouse}
                                                        selectedValue={countryCode}
                                                        clearable={false}
                                                        data-test-auto="271C2A00-2C22-4E8A-9EEF-966E58AAE121"
                                                    />
                                                </div>

                                                <PhoneNumberComponent
                                                    phoneNumber={phoneNumber ?? ""}
                                                    handleChangePhoneNumber={onChangePhoneNumber}
                                                    data-test-auto="8243250E-2983-4267-B864-8EFA449BDAAB"
                                                    className="my-account-phonenumber"
                                                ></PhoneNumberComponent>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </Modal.Body>
                {props.spouse ? (
                    <Modal.Footer>
                        <Button
                            id="btnHideMyaccountPopUp"
                            onClick={onHide}
                            data-testid="cancel"
                            data-test-auto="CEC8781D-ED44-4B24-AF4A-8005A00B59FC"
                            className="btn btn-white btn-default rounded bootbox-cancel"
                            variant={"light"}
                        >
                            <i className="fa fa-times"></i> Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                props.onSaveButtonClick(emailAddress ?? "", phoneNumber ?? "", countryCode ?? "");
                            }}
                            data-testid="saveClientInfo"
                            data-test-auto="56A7B8B9-02D6-498E-8953-F7F56C4478AE"
                            className="btn btn-primary rounded bootbox-accept"
                            variant={"primary"}
                        >
                            <i className="fa fa-save"></i> Save
                        </Button>
                    </Modal.Footer>
                ) : (
                    <Modal.Footer>
                        <Button
                            id="btnHideMyaccountPopUp"
                            onClick={props.closeMyAccount}
                            data-testid="done"
                            data-test-auto="B0C636D5-9829-42E6-97C6-A721AC76F635"
                            variant={"primary"}
                            className="btn btn-primary rounded bootbox-accept"
                        >
                            <span>Done</span>
                        </Button>
                    </Modal.Footer>
                )}
            </Modal>
        </>
    );
};
