import * as React from "react";

export interface IHeaderProps {
    companyName: string;
    companyLogo: string;
}

export const Header: React.FC<IHeaderProps> = (props) => {
    return (
        <div className="row header-container" style={{ backgroundColor: "#45b2e8" }}>
            <div className={"header-nav"} style={{ width: "100%", justifyContent: "center" }}>
                {props.companyLogo !== "" ? (
                    <img className="company-logo" src={props.companyLogo} />
                ) : (
                    <h2 className={"title"} style={{ color: "blue", fontSize: "15px", fontWeight: "normal" }}>
                        {props.companyName}
                    </h2>
                )}
            </div>
        </div>
    );
};
