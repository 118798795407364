import { Action, Reducer } from "@reduxjs/toolkit";
import { actionTypes } from "redux/actionTypes";
import {
    DispatchAction, initialUploadedDocumentStoreState, 
    KnownAction, UploadedDocumentStoreState
} from "./model";


export const reducer: Reducer<UploadedDocumentStoreState, KnownAction> = (
    state: UploadedDocumentStoreState = initialUploadedDocumentStoreState,
    incomingAction: Action) => {

    const action = incomingAction as DispatchAction;

    const currentState = Object.assign({}, state);

    switch (action.type) {

        case actionTypes.RECEIVE_UPLOADED_DOCUMENT_COMPLETED: {
            return { ...currentState, isCompleted: action.completed }
        }

        case actionTypes.UPLOADED_DOCUMENT_LOADER: {
            return { ...currentState, loading: action.loading }
        }

        case actionTypes.RECEIVE_UPLOADED_DOCUMENT_STATUS: {
            return { ...currentState, isCompleted:action.payload ? action.payload.isSourceDocumentCompleted : false , uploadedDocumentStatus: action.payload }
        }

        case actionTypes.UPLOADED_DOCUMENT_ERROR: {
            return { ...currentState, isError: action.isError, errorMessage: action.errorMessage }
        }

        case actionTypes.UPLOADED_DOCUMENT_ERROR_RESET: {
            return { ...currentState, isError: false, errorMessage: "" }
        }

        default:
            return currentState || initialUploadedDocumentStoreState;
    }
}