import * as React from "react";
import { IClientInfo } from "../../../models";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";

export interface IShimmerProps {
    lineCount?: number;
    height?: number | string;
    width?: number | string;
}
export const Shimmer: React.FC<IShimmerProps> = (props) => {
    const { clientInfo } = useSelector((state: { clientInfo: IClientInfo }) => state);
    return <Skeleton count={props.lineCount} width={props.width} height={props.height} />;
};
