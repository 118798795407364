import { IUserModel, IBrandingSettings, UserModel, initialBrandingSettings, IProfileData, initialProfileData } from "../index";

export interface IBaseHeaderInfoViewModel {
    clientName: string;
    taxYear: number;
    companyName: string;
    companyLogoPath: string;
    companyWhiteLogoPath: string;
    contactPerson: IUserModel;
    brandingSettings: IBrandingSettings;
    isPreview: boolean;
    profileData:IProfileData;
}

export class BaseHeaderInfoViewModel implements IBaseHeaderInfoViewModel {
    clientName: string;
    taxYear: number;
    companyName: string;
    companyLogoPath: string;
    companyWhiteLogoPath: string;
    contactPerson: IUserModel;
    brandingSettings: IBrandingSettings;
    isPreview: boolean;
    profileData: IProfileData;
    constructor(
        clientName: string,
        taxYear: number,
        companyName: string,
        companyLogoPath: string,
        companyWhiteLogoPath: string,
        contactPerson: IUserModel,
        brandingSettings: IBrandingSettings,
        isPreview: boolean,
        profileData: IProfileData,
    ) {
        this.clientName = clientName;
        this.taxYear = taxYear;
        this.companyName = companyName;
        this.companyLogoPath = companyLogoPath;
        this.companyWhiteLogoPath = companyWhiteLogoPath;
        this.contactPerson = contactPerson;
        this.brandingSettings = brandingSettings;
        this.isPreview = isPreview;
        this.profileData = profileData;
    }

    public static createNullObject(): IBaseHeaderInfoViewModel {
        return new BaseHeaderInfoViewModel(
            "",
            0,
            "",
            "",
            "",
            UserModel.createNullObject(),
            initialBrandingSettings,
            false,
            initialProfileData
        );
    }
}
