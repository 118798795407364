export interface IBaseClientInfo {
    companyName: string;
    logoPath: string;
    taxYear: number | null;
    clientName: string;
    contactAddress: any;
    returnUrl: string;
    data: string;
    redirectUrl: string;
    isLoading: boolean;
}

export class BaseClientInfo implements IBaseClientInfo {
    companyName: string;
    logoPath: string;
    taxYear: number | null;
    clientName: string;
    contactAddress: any;
    returnUrl: string;
    data: string;
    redirectUrl: string;
    isLoading: boolean;

    constructor(
        companyName: string,
        logoPath: string,
        taxYear: number,
        clientName: string,
        contactAddress: any,
        returnUrl: string,
        data: string,
        redirectUrl: string,
        isLoading: boolean
    ) {
        this.companyName = companyName;
        this.logoPath = logoPath;
        this.taxYear = taxYear;
        this.clientName = clientName;
        this.contactAddress = contactAddress;
        this.returnUrl = returnUrl;
        this.data = data;
        this.redirectUrl = redirectUrl;
        this.isLoading = isLoading;
    }

    public static createNullObject(): IBaseClientInfo {
        return new BaseClientInfo("", "", 0, "", "", "", "", "", false);
    }
}

export interface AuthResponse<T> {
    data: T;
    isError: boolean;
    errorStatus: ErrorStatus;
}

export enum ErrorStatus {
    None = 0,
    InvalidLink = 1,
    OrganizerDeleted = 2,
    ClosedByFirm = 3,
    OrganizerWithOldCustomQuestion = 4
}