import { UploadedDocument } from "../../../../../models";
import { DataAccessFactory } from "../../../../utilities/dataAccess/factory/DataAccessFactory";

const DataAccess: any = DataAccessFactory.GetDataAccessService();

const _baseUrl: string = "api/OrganizerAdditionalDocument/";

export const DocumentsPageServices = {
    getUploadSasUrl: (clientId: string, fileName: string) => {
        return DataAccess.get(_baseUrl + "GetAdditionDocumentUploadLink/" + clientId + "?fileName=" + fileName);
    },

    getUploadedSasLink: (clientId: string, fileName: string) => {
        return DataAccess.get(_baseUrl + "GetUploadedDocumentUrlAsync/" + clientId + "?fileName=" + fileName);
    },

    getUploadedDocumentStream: (clientId: string, fileName: string) => {
        const config = { responseType: "blob" };
        return DataAccess.getWithConfig(
            _baseUrl + "GetUploadedDocumentStreamAsync/" + clientId + "?fileName=" + fileName,
            config
        );
    },

    getBookmarks: (clientId: string, excludeSourceDocuments: boolean) => {
        return DataAccess.get(
            _baseUrl + "GetUplodFormInfoAsync/" + clientId + "?excludeEnabledSourceDocument=" + excludeSourceDocuments
        );
    },

    addUploadedDocument: (clientId: string, uploadedDocument: UploadedDocument) => {
        return DataAccess.postJson(uploadedDocument, _baseUrl + "Add/" + clientId);
    },

    getUploadedDocumentsByFormId: (clientId: string, formId: number) => {
        return DataAccess.get(_baseUrl + "GetUploadedDocument/" + clientId + "?formId=" + formId);
    },

    getUploadedDocuments: (clientId: string) => {
        return DataAccess.get(_baseUrl + "Get/" + clientId);
    },

    completeDocumentUpload: (clientId: string, deletedFiles: string) => {
        return DataAccess.postJson(
            {
                deletedFiles: deletedFiles
            },
            _baseUrl + "ContinueLater/" + clientId
        );
    },

    deleteUploadedDocument: (clientId: string, id: number) => {
        return DataAccess.delete(id, _baseUrl + "Delete/" + clientId + "?uploadedDocumentId=" + id);
    }
};
