import * as React from "react";
import { Modal, Button } from "react-bootstrap";

export interface ILogoutProps {
    show: boolean;
    handleNo(): void;
    handleYes(): void;
    message: string;
}

export const ConfirmModal: React.FC<ILogoutProps> = (props) => {
    return (
        <Modal show={props.show} className="welcome-popup-message">
            <Modal.Header>
                <h5 className="modal-title">
                    <i className="text-secondary"></i>Confirmation
                </h5>
                <button type="button" className="close" onClick={props.handleNo}>
                    <span aria-hidden="true">×</span>
                </button>
            </Modal.Header>
            <Modal.Body>{props.message}</Modal.Body>
            <Modal.Footer>
                <Button className="btn btn-primary btn btn-primary pull-right" onClick={props.handleYes} data-test-auto="D476F021-66C4-453A-BCB7-8A6112E0B139">
                    Yes
                </Button>
                <Button className="btn  btn-default logout-no pull-right" onClick={props.handleNo} data-test-auto="77731EA9-00BB-48B4-8D81-C4A2398C77F3">
                    No
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
