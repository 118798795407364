import React, { useState, useEffect,useRef } from "react";
import { Button, Modal, Col, Row } from 'react-bootstrap';
import Countdown from './countDown';
import { SessionTimeout as TimeOutSvg } from '../../../components/common/index';

export interface ISessionTimeoutProps {
    showModal: boolean;
    onStayAlive(event: any): void;
    countDownMinutes: number;
    onLogOut(): void;
}

export default function SessionTimeout(props:ISessionTimeoutProps) {

    let getCountDown = (): Date => {
        const now = new Date();
        let countDown = now;
        countDown.setMinutes(now.getMinutes() + props.countDownMinutes);
        return countDown;
    }

    let onStayAlive = (e: any) => {
        props.onStayAlive(e);
    }

    let onLogout = () => {
        props.onLogOut();
    }

    return (
        <>
        <div data-testid="sessionTimeoutModalContainer" style={{ position: "relative" }}>
            <Modal 
                className="session-timeout-modal"
                show={props.showModal}
                onHide={onLogout}>   
                <Modal.Header closeButton>
                <Modal.Title>
                    Security
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col lg={12}>
                        <TimeOutSvg
                            width={75}
                            height={100}
                            className="svg-shadow"
                        />
                        <span className="text-center-align" style={{ color: '#337ab7',fontSize:'30px', margin: '0' }}> Session Expired</span>
                        <br/>
                        <div className="text-center-align">
                            Your session has expired. Please log in again.
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    data-testid="seesionTimeOutLogInClick"
                    data-test-auto="411110AF-725E-45AD-8CFF-37790FC8B7C1"
                    variant={"primary"}
                    onClick={onLogout}
                    className="btn btn-primary rounded bootbox-accept"
                ><i className='fa fa-check-double'></i> Log In</Button>
            </Modal.Footer>
            </Modal>
        </div>
    </>
    );
}