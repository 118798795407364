import { combineReducers } from "redux";
import { loader } from "./loader";
import { users } from "./users";
import { engagementLetterDocument } from "./esign/engagementLetterDcoument";
import { organizerMetadata } from "./esign/organizerMetadata";
import { clientInfo } from "./esign/clientInfo";
import { baseClientInfoMetaData } from "./coverPage/baseClientInfo";
import { headerInfoMetaData } from "./header/headerInfoMetaData";
import { otpReducer } from "./otp/otpReducer";
import { errorReducer } from "./error/errorReducer";
import { headerTitle } from "./header/headerTitle";
import { baseHeaderInfoMetaData } from "./header/baseHeaderInfoMetaData";
import { bookmarks } from "./documentsPage/bookmarks";
import { uploadedDocuments } from "./documentsPage/uploadedDocuments";
import { uploadedDocumentsByFormId } from "./documentsPage/uploadedDocumentsByFormId";
import { documentClientsMetaData } from "./organizer/documentClientsMetaData";
import { viewDocuments } from "./documentsPage/viewDocuments";
import { formId } from "./documentsPage/formId";
import {deletedDocuments} from './documentsPage/deletedDocuments'
import { preparerMessage } from "./esign/preparerMessage";
import { reducer as uploadedDocumentStatusReducer } from "./uploadedDocumentStatus/reducer";
import { reducer as headerActionButtonReducer } from "./header/headerActionButton";

export default combineReducers({
    loader,
    users,
    engagementLetterDocument,
    organizerMetadata,
    clientInfo,
    baseClientInfoMetaData,
    headerInfoMetaData,
    otpReducer,
    errorReducer,
    headerTitle,
    baseHeaderInfoMetaData,
    bookmarks,
    uploadedDocuments,
    uploadedDocumentsByFormId,
    documentClientsMetaData,
    viewDocuments,
    formId,
    deletedDocuments,
    preparerMessage,
    uploadedDocumentStatusReducer,
    headerActionButtonReducer
});
