import { DataAccessFactory } from "../../../../utilities/dataAccess/factory/DataAccessFactory";

const DataAccess: any = DataAccessFactory.GetDataAccessService();

export const CoverPageServices = {
    getAuthenticationType: (clientId: string) => {
        return DataAccess.get("api/Coverpage/GetAuthenticationTypeAsync/" + clientId);
    },
    getClientInfo: (clientGuid: string) => {
        return DataAccess.get("api/Coverpage/GetClientInfo/" + clientGuid);
    }
};
