import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import { store } from "./redux/store";
import "awesome-pdf-viewer/dist/index.css";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/css/site.css";
import "./core/utilities/dataAccess/axios-interceptor";

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);
