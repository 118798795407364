import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EngamentLetterCompletedViewer } from "../../signCompleted/engagementLetterSignCompleted/parts/viewer";
import { EnagementLetterSignCompletedFooter } from "../../signCompleted/engagementLetterSignCompleted/parts/footer";
import { useParams } from "react-router-dom";
import { esignAction, HeaderInfoAction } from "../../../../redux/actions";
import { IFileUtilities } from "../../../../core/utilities";
import { OrganizerConstants, EngagementLetterConstants } from "../../../../common";
import { useHistory } from "react-router";
import { container, TYPES } from "../../../../core/startup";
import { DisplayDownloadFile } from "common/DisplayDownloadFile";
import { IOrganizerMetadata } from "models/organizer/organizer";

const FileUtilities = container.get<IFileUtilities>(TYPES.IFileUtilities);

export const EngamentLetterSignCompleted: React.FC<{}> = (props) => {
    const params: any = useParams();
    let _viewerRef: any;
    const dispatch = useDispatch();
    const [downloadUrl, setDownloadUrl] = useState<string>("");
    const history = useHistory();
    const { organizerMetadata } = useSelector((state: { organizerMetadata: IOrganizerMetadata }) => state);

    useEffect(() => {
        dispatch(esignAction.getOrganizerMetadata(params.id));
        dispatch(HeaderInfoAction.setHeaderText(EngagementLetterConstants.ViewHeaderTitle));
        dispatch(
            esignAction.getEngagemementLetterDownloadLink(params.id, (response: any) => {
                setDownloadUrl(response.data.sas);
            })
        );
    }, [dispatch]);

    let onDownload = () => {
        dispatch(esignAction.getSignedSignatureDocuments(params.id));
    };


    let onBack = () => {
        handleRedirect(OrganizerConstants.WelcomeScreenURL + params.id);
    };

    let handleRedirect = (url: string) => {
        history.push(url);
    };

    return (
        <>
            <div data-testid="esignCompleted" className="signing-page-main-container">
                <EngamentLetterCompletedViewer
                    ref={(ref: any) => {
                        _viewerRef = ref;
                    }}
                    documetnUrl={downloadUrl}
                    clientId={params.id}
                />
            </div>
            <div data-testid="esignFooter" className="footer-container">
                <EnagementLetterSignCompletedFooter onDownload={onDownload} onBack={onBack} />
            </div>
        </>
    );
};
