import { ModalNotification } from "components/common/notification/ModalNotification";
import { TrashIcon } from "components/common/svg/TrashIconComponent";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { UploadedDocumentStoreState } from "redux/reducers/uploadedDocumentStatus/model";
import { images } from "../../../assets";
import { OrganizerConstants, OrganizerUploadedDocuments } from "../../../common";
import {
    documentAction,
    actionCreators as uploadedDocumentStatusAction
} from "../../../redux/actions";
import { ConfirmModal, MessageBox, ViewerModal } from "../../common";
import { Logger } from "../../../App";
import { IEventTelemetry } from "@microsoft/applicationinsights-web";
import { useHandleFailureCallbackOnConflict } from "components/common/hooks/useHandleFailureCallbackOnConflict";

export const ViewDocumentsPage: React.FC<{}> = () => {
    const { jpgIcon, pngIcon, pdfIcon, wordIcon, excelIcon, previewIcon, trashIcon, download } = images;
    const handleFailureCallbackOnConflict = useHandleFailureCallbackOnConflict();
    const dispatch = useDispatch();
    const params: any = useParams();
    const history: any = useHistory();

    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [showViewer, setShowViewer] = useState<boolean>(false);
    const [sourceUrl, setSourceUrl] = useState<string>("");
    const [sourceFileType, setSourceFileType] = useState<string>("");
    const [sourceFileName, setSourceFileName] = useState<string>("");
    const [id, setId] = useState(-1);
    const [deletedFilename, setDeletedFilename] = useState<string>("");

    const { formId, uploadedDocumentsByFormId } = useSelector((state: any) => state);

    const {
        isCompleted, isError, errorMessage
    } = useSelector((state: { uploadedDocumentStatusReducer: UploadedDocumentStoreState }) => state.uploadedDocumentStatusReducer);


    useEffect(() => {
        if (formId[0] === -1) {
            history.push(OrganizerConstants.UploadDocumentPageURL + params.id);
        } else {
            dispatch(documentAction.getUploadedDocumentsByFormId(params.id, formId[0]));
        }
    }, [formId[0]]);

    const callLater = (deletedSourceDocumentId: number) => {
        MessageBox.Success(OrganizerUploadedDocuments.DeleteSuccess);
        dispatch(documentAction.getUploadedDocumentsByFormId(params.id, formId[0]));
        dispatch(documentAction.getDeletedDocuments(deletedFilename));
        const traceEvent: IEventTelemetry = {
            name: "Delete Source Document",
            properties: {
                Page: "Upload Documents Page from Mobile",
                SourceDocumentId: deletedSourceDocumentId,
                SourceDocumentName: deletedFilename
            }
        };
        Logger.trackEvent(traceEvent);
    };

    const deleteDocument = (id: number) => {
        if (id >= 0) {
            dispatch(documentAction.deleteUploadedDocument(
                params.id,
                id,
                () => callLater(id),
                () => { handleFailureCallbackOnConflict(params.id) }));
            setShowDeletePopup(false);
        }
    };

    const downlodSourceDocument = (data: any) => {
        dispatch(
            documentAction.downlodSourceDocument(
                data.fileName,
                params.id)
        );
    };

    const previewDocument = (dataId: number, fileName: string) => {
        setId(dataId);
        dispatch(
            documentAction.getUploadedSasLink(params.id, fileName, (sasUrl: string) => {
                setSourceUrl(sasUrl);
                const traceEvent: IEventTelemetry = {
                    name: "Preview Source Document",
                    properties: {
                        Page: "Upload Documents Page from Mobile",
                        SourceDocumentId: dataId,
                        SourceDocumentName: fileName
                    }
                };
                Logger.trackEvent(traceEvent);
            })
        );
    };

    const closeModal = () => {
        setShowDeletePopup(false);
    };

    const toggleViewerModal = () => {
        setShowViewer(!showViewer);
        setSourceUrl("");
    };

    const onErrorPopupClose = () => {
        dispatch(documentAction.getUploadedDocuments(params.id));
        dispatch(uploadedDocumentStatusAction.resetUploadedDocumentError());
    }

    useEffect(() => {
        if (sourceUrl.length > 1) {
            setShowViewer(true);
        }
    }, [sourceUrl]);

    const onBackAction = () => {
        history.push(OrganizerConstants.UploadDocumentPageURL + params.id)
    }

    return (
        <>
            <div data-testid="viewDocuments" className="view-documents">
                <p data-testid="bookmarkSectionName" className="view-documents-bookmark">
                    <strong>{formId[1]}</strong>
                </p>
                <div className="view-documents-content">
                    {uploadedDocumentsByFormId.map((data: any) => {
                        const split: any[] = data.fileName.split(".");
                        const fileExtension: string = split[split.length - 1].toLowerCase();

                        const iconList: any[] = [
                            ["jpg", jpgIcon],
                            ["jpeg", jpgIcon],
                            ["png", pngIcon],
                            ["pdf", pdfIcon],
                            ["xls", excelIcon],
                            ["xlsx", excelIcon],
                            ["doc", wordIcon],
                            ["docx", wordIcon]
                        ];

                        const [fileType, icon] = iconList.filter((e) => e[0] === fileExtension)[0];

                        return (
                            <div data-testid={`bookmarkContent ${data.id}`} key={data.id}>
                                <div className="view-documents-content-row">
                                    <img data-testid="iconType" className="view-documents-content-col1" src={icon.src} alt={icon.alt} />
                                    <div className="view-documents-content-col2">
                                        <p>{data.fileName}</p>
                                        <div>
                                            <img
                                                data-testid="previewIcon"
                                                className="preview-icon"
                                                src={previewIcon.src}
                                                alt={previewIcon.alt}
                                                onClick={() => {
                                                    previewDocument(data.id, data.fileName);
                                                    setSourceFileType(fileType);
                                                    setSourceFileName(data.fileName);
                                                }}
                                            />
                                            <span onClick={() => {
                                                if (!isCompleted) {
                                                    setId(data.id);
                                                    setShowDeletePopup(true);
                                                    setDeletedFilename(data.fileName)
                                                }
                                            }}>
                                                <TrashIcon height="20px" width="24px" color={isCompleted ? "#ff000052" : "#E00D0D"} />
                                            </span>
                                            <img
                                                className="download-icon"
                                                src={download.src}
                                                alt={download.alt}
                                                onClick={() => downlodSourceDocument(data)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="footer-container">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pull-right download-footer steps-footer">
                    <footer className="footer">
                        <a className="rd-nav-link btn-finish">
                            <button
                                data-test-auto="d50c4ace-bbe3-11eb-8529-0242ac130003"
                                type="submit"
                                onClick={onBackAction}
                                className="btn btn-secondary btn-sm"
                                id="btnSkip"
                            >
                                Back
                            </button>
                        </a>
                    </footer>
                </div>
            </div>
            <ConfirmModal
                show={showDeletePopup}
                handleNo={closeModal}
                handleYes={() => deleteDocument(id)}
                message={OrganizerUploadedDocuments.FileDeleteConfirmation}
            ></ConfirmModal>
            <ViewerModal
                show={showViewer}
                toggleViewerModal={toggleViewerModal}
                sourceUrl={sourceUrl}
                sourceFileType={sourceFileType}
                sourceFileName={sourceFileName}
                clientId={params.id}
            />

            {
                isError &&
                <ModalNotification
                    show={isError}
                    modalBodyText={errorMessage}
                    okButtonEnabled={true}
                    okButtonText={"Ok"}
                    onOkButtonClick={onErrorPopupClose}
                />
            }
        </>
    );
};
