import { useHistory } from 'react-router-dom';
import { MessageBox } from '../notification/notification';
import { OrganizerConstants, OrganizerStatusChangedWarning } from 'common';

export const useHandleFailureCallbackOnConflict = () => {
    const history = useHistory();

    const handleFailureCallbackOnConflict = (clientId: string) => {
        MessageBox.Error(OrganizerStatusChangedWarning);
        history.push(OrganizerConstants.WelcomeScreenURL + clientId);
    };

    return handleFailureCallbackOnConflict;
};