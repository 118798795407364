import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IClientInfo, FormGroupStatus, IOrganizerMetadata, IElForm, GroupType, ELDocType, IDocumentType, ControlType, IControl } from "./../../../../models";
import { IsPreviewMode } from "./../../../common/helper/helper";
import { esignAction, HeaderInfoAction } from "../../../../redux/actions";
import { useParams } from "react-router-dom";
import { OrganizerConstants, ErrorMessages, EngagementLetterConstants, OrganizerStatusChangedWarning } from "../../../../common/constants/constant";
import { EnagementLetterFooter } from "../engagementLetterSign/parts/footer";
import { EngagementLetterSignHelper } from "../helper/helper";
import { useHistory } from "react-router";
import { MessageBox } from "../../../../components/common";
import { ELDocControlState, IElDocument, SignerDocumentModel } from "models/organizer/organizer";
import { EngagementLetterView } from "./parts/engagementLetterView";
import { DeclineModal } from "./DeclineModal";

export const DocumentViewer: React.FC<{}> = (props) => {
    let _pdfView: any;
    const params: any = useParams();
    const history = useHistory();

    const { engagementLetterDocument } = useSelector(
        (state: { engagementLetterDocument: SignerDocumentModel }) => state
    );
    const { organizerMetadata } = useSelector((state: { organizerMetadata: IOrganizerMetadata }) => state);
    const { clientInfo } = useSelector((state: { clientInfo: IClientInfo }) => state);
    const [finishEnabled, setFinishEnabled] = useState<boolean>(false);
    const [signingCompleted, setSigningCompleted] = useState<boolean>(false);
    const [showSpouseInfo, setShowSpouseInfo] = useState<boolean>(false);
    const [isSkipped, setSkipped] = useState<boolean>(false);
    const [documents, setDocuments] = useState<IElDocument[]>([]);
    const [client, setClient] = useState<any>();
    const [showDeclineModal, setShowDeclineModal] = useState<boolean>(false);
    const [controlStatus, setControlStatus] = useState<ELDocControlState>(ELDocControlState.None);
    const [engagementLetterStatus, setEngagementLetterStatus] = useState<FormGroupStatus>(FormGroupStatus.None);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(esignAction.getOrganizerMetadata(params.id));
        dispatch(HeaderInfoAction.setHeaderText(EngagementLetterConstants.SignHeaderTitle));
        dispatch(
            esignAction.getDocumentStatusAndSourceFileSettings(params.id, (response: any) => {
                validateEngagementStatus(response.data.engagementLetterStatus);
            })
        );
    }, [dispatch]);

    let validateEngagementStatus = (engagementLetterFormGroupStatus: FormGroupStatus) => {
        if (engagementLetterFormGroupStatus === FormGroupStatus.ESigned ||
            engagementLetterFormGroupStatus === FormGroupStatus.ManuallySigned) {
            handleRedirect(OrganizerConstants.ViewandDownloadEngagementLetterPageURL + params.id);
        } else {
            dispatch(esignAction.getEngagementDocument(params.id, engagementLetterDocument.id === 0, (response: any) => {
                setControlStatus(getControlStatus(response));
            }));
            setEngagementLetterStatus(engagementLetterFormGroupStatus);
        }
    };

    const getControlStatus = (engagementDocument: SignerDocumentModel) => {
        let elDocs: IElDocument[] = [];
        const client: any = engagementDocument.organizerClients &&
            engagementDocument.organizerClients.find((x: any) => x.clientGuid === params.id);
        setClient(client);
        if (engagementDocument.formGroup !== undefined) {
            const engagementFormGroup = engagementDocument.formGroup.find((x: any) => x.type === GroupType.Engagement);
            if (engagementFormGroup !== undefined) {
                const controlsFound = engagementFormGroup.forms.length <= 0 ?
                    true : engagementFormGroup.forms.some((x: any) => x.formData
                        && x.formData.controlList.some((x: any) => x.controlRole === client?.clientType && x.controlType !== ControlType.Date) === true && x.formData.controlList.length > 0);
                let elForms: IElForm[] = [];
                if (controlsFound) {
                    const formWithControls = engagementFormGroup.forms.filter((x: any) => x.formData
                        && x.formData.controlList.filter((x: any) => x.controlRole === client?.clientType) && x.formData.controlList.length > 0);

                    formWithControls.forEach((form: any) => {
                        elForms.push({
                            controls: form.formData.controlList.filter((x: any) => x.controlRole === client?.clientType),
                            pageNo: form.individualFilePageNo,
                            type: ELDocType.EL
                        })
                    });
                }
                elDocs.push({
                    id: engagementDocument.id,
                    url: engagementDocument.documentUrl,
                    forms: elForms,
                    type: ELDocType.EL,
                    documentType: IDocumentType.None,
                    fileGuid: engagementDocument.fileGuid,
                });
            }
        }

        if (engagementDocument.additionalEsign.length > 0) {
            const additionalDocs = engagementDocument.additionalEsign;
            additionalDocs.forEach((doc: any) => {
                let elForms: IElForm[] = [];
                const sortFormData = doc.documentControls?.formData?.sort((a: any, b: any) => a.pageNo - b.pageNo);
                sortFormData?.forEach((form: any) => {
                    elForms.push({
                        controls: form.controlList.filter((x: any) => x.controlRole === client.clientType),
                        pageNo: form.pageNo,
                        type: ELDocType.Additional
                    })
                });
                elDocs.push({
                    id: doc.id,
                    url: doc.sasURL,
                    forms: elForms,
                    type: ELDocType.Additional,
                    fileName: doc.fileName,
                    documentType: doc.documentType,
                    fileGuid: doc.fileGuid
                });
            })
        }

        const organizerFormGroup = engagementDocument.formGroup.find((x: any) => x.type === GroupType.OrganizerWithSign);
        if (organizerFormGroup !== undefined) {
            const controlsFound = organizerFormGroup.forms.length <= 0 ?
                true : organizerFormGroup.forms.some((x: any) => x.formData
                    && x.formData.controlList.some((x: any) =>
                        x.controlRole === client.clientType &&
                        x.controlType !== ControlType.Date) === true &&
                    x.formData.controlList.length > 0);
            const organizerSignForms: IElForm[] = [];
            if (controlsFound) {
                const formWithControls = organizerFormGroup.forms.filter((x: any) => x.formData
                    && x.formData.controlList.filter((x: any) => x.controlRole === client.clientType) && x.formData.controlList.length > 0);

                formWithControls.forEach((form: any) => {
                    organizerSignForms.push({
                        controls: form.formData.controlList.filter((x: any) => x.controlRole === client.clientType),
                        pageNo: form.individualFilePageNo,
                        type: ELDocType.OrganizerSignDocument
                    })
                });
            }

            elDocs.push({
                id: engagementDocument.id + 1,
                url: engagementDocument.organizerFormGroupUrl,
                forms: organizerSignForms,
                type: ELDocType.OrganizerSignDocument,
                documentType: IDocumentType.organizerWithSignatureDocument,
                fileGuid: engagementDocument.fileGuid,
            });
        }
        setDocuments(elDocs);
        return getControlStatusFromDocument(elDocs)
    }

    const getControlStatusFromDocument = (elDocs: IElDocument[]) => {
        let allControls: IControl[] = [];

        elDocs.forEach(({ forms }) => {
            forms.forEach(({ controls }) => {
                allControls = [...allControls, ...controls];
            })
        })

        if (allControls.length < 1) {
            return ELDocControlState.NoControles;
        }
        if (allControls.filter(({ required }) => required === true).length > 0) {
            return ELDocControlState.RequiredControlExists;
        }
        else {
            return ELDocControlState.RequiredControlNotExists;
        }
    }

    let handleSigningCompleted = () => {
        _pdfView && _pdfView.finishEnabled() && setFinishEnabled(true);

    }

    let handleRedirect = (url: string) => {
        history.push(url);
    };

    let handleFinish = () => {
        setSigningCompleted(true);
        if (!handleSkipAndFinishForPreview()) {
            setSkipped(false);
            if (showSpouse()) {
                setShowSpouseInfo(true);
            } else {
                const controlList = _pdfView.getDocumentControlInfo();
                submitSignedSignDocument(getSignedModel(controlList));
            }
        }
    };

    const getSignedModel = (controlList: any) => {
        let tmpEngagementDocumentModel = EngagementLetterSignHelper.getServerModel(
            controlList,
            engagementLetterDocument
        );
        tmpEngagementDocumentModel = EngagementLetterSignHelper.getAdditionalEsignServerModel(controlList, tmpEngagementDocumentModel);
        return tmpEngagementDocumentModel;
    };

    let handleUpdateSpouseEmail = (clientInfo: IClientInfo, controlList: any) => {
        dispatch(
            esignAction.updateSpouseEmail(params.id, clientInfo, () => {
                toggleSpouseInfoModal();
                if (isSkipped) {
                    skipEngagementSignature();
                } else {
                    submitSignedSignDocument(getSignedModel(controlList));
                }
            })
        );
    };

    const submitSignedSignDocument = (documentModel: any) => {
        dispatch(
            esignAction.submitSignedDocument(
                params.id,
                documentModel,
                () => {
                    handleRedirect(OrganizerConstants.WelcomeScreenURL + params.id);
                },
                () => {
                    MessageBox.Error(ErrorMessages.DocumentSubmissionFailed);
                },
                () => {
                    MessageBox.Error(OrganizerStatusChangedWarning);
                    handleRedirect(OrganizerConstants.WelcomeScreenURL + params.id);
                }
            )
        );
    };

    let showSpouse = (): boolean => {
        if (
            clientInfo &&
            clientInfo.id > 0 &&
            clientInfo.isDeceased == false &&
            engagementLetterStatus != FormGroupStatus.Reviewed &&
            engagementLetterStatus != FormGroupStatus.PartiallySigned &&
            clientInfo.documentSigner
        ) {
            return true;
        } else {
            return false;
        }
    };

    let handleSkipAndFinishForPreview = (): boolean => {
        return isPreviewMode();
    };

    let isPreviewMode = (): boolean => {
        if (IsPreviewMode(organizerMetadata)) {
            return true;
        }
        return false;
    };

    let onDecline = () => {
        if (!handleSkipAndFinishForPreview()) {
            setSkipped(true);
            if (showSpouse()) {
                setShowSpouseInfo(true);
            } else {
                skipEngagementSignature();
            }
        }
    };

    let skipEngagementSignature = () => {
        dispatch(
            esignAction.skipEngagementSign(params.id, engagementLetterDocument, () => {
                handleRedirect(OrganizerConstants.WelcomeScreenURL + params.id);
            })
        );
    };

    let nextEnabled = (): boolean => {
        return isPreviewMode() || finishEnabled;
    };

    let toggleSpouseInfoModal = () => {
        setShowSpouseInfo(!showSpouseInfo);
    };

    const requestSpouseInfo = () => {
        dispatch(esignAction.getSpouseInfo(params.id));
    };

    const handleDecline = () => {
        setSigningCompleted(true);
        if (controlStatus !== ELDocControlState.NoControles) {
            setShowDeclineModal(true);
        } else {
            onDecline();
        }
    }

    const handleConfirm = () => {
        setShowDeclineModal(false);
        onDecline();
    }

    const handleCancel = () => {
        setShowDeclineModal(false);
    }

    const checkControlFound = () => {
        let controlpresent = documents.filter(y => y.forms.some((x) => x.controls.some((x: any) => x.controlRole === client?.clientType
            && x.controlType !== ControlType.Date && x.controlData === null) === true)).length > 0;

        let requiredControlPresent = documents.filter(y => y.forms.some((x) => x.controls.some((x: any) => x.controlRole === client?.clientType
            && x.controlType !== ControlType.Date && x.required === true) === true)).length > 0;

        return controlpresent && requiredControlPresent;
    }

    const isFinishEnabled = () => {
        return isPreviewMode() || signingCompleted || !checkControlFound();
    }

    const updateSignOnControlChange = (allSigned: boolean) => {
        setSigningCompleted(allSigned);
    }

    return (
        <>
            <DeclineModal
                show={showDeclineModal}
                handleCancel={handleCancel}
                handleConfirm={handleConfirm}
            />
            <div className="signing-page-main-container" data-testid="documentViewerWrapper">
                {
                    <EngagementLetterView
                        ref={(ref: EngagementLetterView) => (_pdfView = ref)}
                        engagementDocument={engagementLetterDocument}
                        clientInfo={clientInfo}
                        organizerMetadata={organizerMetadata}
                        submitSignedDocument={submitSignedSignDocument}
                        skipEngagementSign={skipEngagementSignature}
                        isPreviewMode={isPreviewMode}
                        handleSkipAndFinishForPreview={handleSkipAndFinishForPreview}
                        requestSpouseInfo={requestSpouseInfo}
                        clientId={params.id}
                        onUpdateSpouseEmail={handleUpdateSpouseEmail}
                        handleSigningCompleted={handleSigningCompleted}
                        showSpouseInfo={showSpouseInfo}
                        toggleSpouseInfoModal={toggleSpouseInfoModal}
                        nextEnabled={nextEnabled()}
                        controlStatus={controlStatus}
                        updateSignOnControlChange={updateSignOnControlChange}
                    />
                }
                <div className="sign-footer-container">
                    <EnagementLetterFooter
                        controlStatus={controlStatus}
                        onNext={handleFinish}
                        handleDecline={handleDecline}
                        nextEnabled={nextEnabled()}
                        finishEnabled={isFinishEnabled()}
                    />
                </div>
            </div>
        </>
    );
};
