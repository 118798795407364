import React from "react";

interface DocumentsPageFooterProps {
    isCompleteDisabled: boolean;
    onComplete(): void;
    setRef: (ref: any) => void;
}

export const DocumentsPageFooter: React.FC<DocumentsPageFooterProps> = ({
    isCompleteDisabled,
    onComplete,
    setRef
}) => {

    return (
        <div data-testid="documentsFooter" className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pull-right download-footer steps-footer" ref={(ins) => { setRef(ins) }}>
            <footer className="footer">
                <a className="rd-nav-link btn-finish">
                    <button
                        data-test-auto="d50c4ace-bbe3-11eb-8529-0242ac130004"
                        onClick={onComplete}
                        disabled={isCompleteDisabled}
                        className="btn btn-size btn-complete"
                        id="btnContinue"
                    >
                        Finish
                    </button>
                </a>
            </footer>
        </div>
    );
};
