export enum TaxSoftware {
    None = -1,
    ProSystems = 0,
    UltraTax = 1,
    GoSystem = 2,
    UltraTaxM18 = 3,
    Lacerte = 4,
    Drake = 5
}

export enum DocumentStatus {
    None = 0,
    Uploaded = 1,
    Ready = 2,
    Error = 3,
    Processing = 4,
    PreparingForDelivery = 5,
    Delivered = 6,
    PartiallyCompleted = 7,
    Completed = 8,
    Downloaded = 9
}
export enum OtpMode {
    None = 0,
    Email = 1,
    Text = 2
}

export enum ClientType {
    Undefied = 0,
    Taxpayer = 1,
    Spouse = 2,
    Partner = 3,
    PartnerShip = 4,
    ShareHolder = 5
}
export enum TextPlacement {
    Left = 1,
    Right = 2,
    Center = 3
}

export enum DataType {
    None = 0,
    AlphaNumeric = 1,
    Numeric = 2,
    SSN = 3,
    TextArea = 4,
    Label = 5,
    Amount = 6
}
export enum GroupType {
    None = 0,
    Organizer = 1,
    Engagement = 2,
    OrganizerWithSign = 6
}

export enum FormGroupStatus {
    None = 0,

    // organizer
    Delievered = 1,
    PartiallyCompleted = 2,
    Completed = 3,
    OrganizerDownloaded = 4,
    Dropped = 5,
    ManuallyCompleted = 6,

    // engagement 
    AwaitingESign = 21,
    PartiallySigned = 22,
    ESigned = 23,
    NA = 25,
    Reviewed = 26,
    Declined = 27,
    ManuallySigned = 28
}

export enum FormType {
    None = 0,
    OrganizerClient = 1,
    Organizer = 2,
    EngagementLetter = 3,
    Removed = 4,
    AdditionalQuestion = 5
}

export enum UploadMethod {
    None = 0,
    Proforma = 1,
    Blank = 2,
    Batch = 3
}

export enum DownloadableStatus {
    Unknown = 0,
    Preparing = 1,
    Ready = 2,
    Delivered = 3
}

export enum IDocumentType {
    ConsentForm=0,
    Additional_EngagementLetter=1,
    Other = 2,
    None = 3,
    organizerWithSignatureDocument = 6
}
