import { ToastContainer, toast } from "react-toastify";
import * as React from "react";
import "react-toastify/dist/ReactToastify.css";

export const MessageBox = {
    Error: (message: string) => toast.error(message, { position: toast.POSITION.BOTTOM_CENTER }),
    Warning: (message: string) => toast.warn(message, { position: toast.POSITION.BOTTOM_CENTER }),
    Success: (message: string) => toast.success(message, { position: toast.POSITION.BOTTOM_CENTER }),
    Info: (message: string) => toast.info(message, { position: toast.POSITION.BOTTOM_CENTER })
};

export const Toaster: React.FC<{}> = () => {
    return (
        <div>
            <ToastContainer
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                pauseOnHover
            />
        </div>
    );
};
