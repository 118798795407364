import { headerInfoTypes } from "../../actionTypes";
import { IBaseHeaderInfoViewModel, BaseHeaderInfoViewModel } from "../../../models/index";
import { Action } from "redux";

const initialState: IBaseHeaderInfoViewModel = BaseHeaderInfoViewModel.createNullObject();

export const baseHeaderInfoMetaData = (state = initialState, incomingAction: Action) => {
    const action = incomingAction as any;
    const currentState = Object.assign({}, state);
    switch (action.type) {
        case headerInfoTypes.headerInfoResponse:
            let data = action.data;
            currentState.clientName = data.clientName;
            currentState.taxYear = data.taxYear;
            currentState.companyName = data.companyName;
            currentState.companyLogoPath = data.companyLogoPath;
            currentState.companyWhiteLogoPath = data.companyWhiteLogoPath;
            currentState.contactPerson = data.contactPerson;
            currentState.isPreview = data.isPreview;
            currentState.taxYear = data.taxYear;
            return { ...currentState };  
        case headerInfoTypes.setProfileData:
            let payload = action.data;
            currentState.profileData=payload;
            return { ...currentState };
        default:
            return state;
    }
};
