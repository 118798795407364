import { injectable } from "inversify";
import "reflect-metadata";

export interface IFileUtilities {
    getExtension(fileName: string): string;
    isValidateSize(file: any, maxSize: number): boolean;
    isValidateExtension(file: any, expectedExtensions: string[]): boolean;
    download(url: string, fileName?: string): void;
    downloadBlob(blob: Blob, fileName: string): void;
    downloadObject(url: string): Promise<any>;
    normalizeFileName(fileName: string): string;
}

@injectable()
export class FileUtilities implements IFileUtilities {
    getExtension(fileName: string): string {
        return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
    }

    isValidateSize(file: any, maxSize: number): boolean {
        return file.size <= maxSize;
    }

    isValidateExtension(file: any, expectedExtensions: string[]): boolean {
        const fileExtension: string = this.getExtension(file);
        return expectedExtensions.indexOf(fileExtension.toLowerCase()) !== -1;
    }

    download(url: string, fileName?: string): void {
        let link: HTMLAnchorElement = document.createElement("a");
        link.href = url;
        if (fileName) {
            link.download = fileName;
        }
        document.body.appendChild(link);
        link.click();
        setTimeout(function () {
            document.body.removeChild(link);
        }, 100);
    }

    downloadBlob(blob: Blob, fileName: string): void {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        var newBlob = new Blob([blob], { type: blob.type });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //     window.navigator.msSaveOrOpenBlob(newBlob, fileName);
        //     return;
        // }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement("a");
        link.href = data;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
            document.body.removeChild(link);
        }, 100);
    }

    downloadObject(url: string): Promise<any> {
        return new Promise(function (resolve, reject) {
            fetch(url)
                .then((data) => data.json())
                .then(function (data) {
                    resolve(data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    normalizeFileName(fileName: string): string {
        var pattern = new RegExp(/[^a-z A-Z 0-9. _\-]+/g);
        let newFileName = fileName.replace(pattern, '_');
        let extension = this.getExtension(fileName);
        return newFileName.replace(new RegExp('\\.' + extension + '$'), `.${extension.toLowerCase()}`)
    }
}
