import { documentPageTypes } from "../../actionTypes";

const initialState: any[] = [];

export const viewDocuments = (state = initialState, action: any) => {
    switch (action.type) {
        case "view-documents":
            return action.payload;

        default:
            return state;
    }
};
