import { viewDocumentsPageTypes } from "../../actionTypes";

const initialState: any[] = [-1, ""];

export const formId = (state = initialState, action: any) => {
    switch (action.type) {
        case viewDocumentsPageTypes.getFormIdAndBookmark:
            return action.payload;

        default:
            return state;
    }
};
