import React, { useEffect } from "react";
import { ELDocControlState } from "models";

interface EnagementLetterFooterProps {
    onNext(): void;
    nextEnabled?: boolean;
    handleDecline(): void;
    finishEnabled: boolean;
    controlStatus: ELDocControlState;
}

export const EnagementLetterFooter: React.FC<EnagementLetterFooterProps> = (props) => {
    useEffect(() => { });
    const {controlStatus} = props;
    return (
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pull-right download-footer footer-panel" data-testid="engagementLetterFooter">
            <footer>
                {/* <a className="rd-nav-link btn-finish">
                    <button
                        data-test-auto="d50c4ace-bbe3-11eb-8529-0242ac130003"
                        type="submit"
                        onClick={props.onSkip}
                        className="btn btn-secondary btn-sm"
                        id="btnSkip"
                    >
                        Skip
                    </button>
                </a> */}
                {controlStatus && controlStatus !== ELDocControlState.NoControles?
                    <a className="rd-nav-link btn-finish">
                        <button
                            data-test-auto="d50c492a-bbe3-11eb-8529-0242ac130003"
                            type="submit"
                            onClick={props.onNext}
                            disabled={!props.finishEnabled}
                            className="btn btn-primary btn-sm pull-right"
                            id="btnFinish"
                        >
                            Finish
                        </button>
                    </a> : ''
                }
                {controlStatus && controlStatus !== ELDocControlState.RequiredControlExists ?
                    <a className="rd-nav-link btn-finish">
                        <button data-test-auto='d50c4ace-bbe3-11eb-8529-0242ac130003' type="submit" onClick={props.handleDecline}
                        className="btn btn-sm btn-decline"
                        id="btnSkip">Decline</button>
                    </a>
                    : ''
                }   
            </footer>
        </div>
    );
};
