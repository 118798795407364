import { ServiceFactory } from "../../../core/services/dataAccess/factory/ServiceFactory";
import { loaderAction } from "./../loaderAction";
import { UploadedDocument } from "../../../models";
import { actionTypes, documentPageTypes, viewDocumentsPageTypes } from "../../actionTypes";
import { AxiosResponse } from "axios";
import { ConflictErrorMessage, ConflictStatusCode, CustomResponse } from "redux/reducers/uploadedDocumentStatus/model";
import { actionCreators } from "../uploadedDocumentStatus/actions";
import { DisplayDownloadFile } from "common/DisplayDownloadFile";

export const documentAction = {
    getUploadSasUrl:
        (clientId: string,
            fileName: string,
            successCallback?: (resp: any) => void,
            failureCallbackOnConflict?: () => void) => async (dispatch: any) => {
                try {
                    const response: AxiosResponse<CustomResponse<any>> = await ServiceFactory.GetDocumentsPageService().getUploadSasUrl(clientId, fileName);
                    if (response) {
                        successCallback && successCallback(response);
                    }
                } catch (error: any) {
                    if (error.response?.status === 423) {
                        dispatch(loaderAction.stop());
                        failureCallbackOnConflict && failureCallbackOnConflict();
                    }
                    else {
                        console.log(error);
                    }
                }
            },

    getUploadedSasLink:
        (clientId: string, fileName: string, successCallback?: (resp: any) => void) => async (dispatch: any) => {
            try {
                dispatch(loaderAction.start());
                const response = await ServiceFactory.GetDocumentsPageService().getUploadedSasLink(clientId, fileName);
                if (response) {
                    successCallback && successCallback(response.data.sas);
                }
                dispatch(loaderAction.stop());
            } catch (error) {
                console.log(error);
                dispatch(loaderAction.stop());
            }
        },
    downlodSourceDocument:
        (fileName: string, clientId: string) => async (dispatch: any) => {
            try {
                dispatch(loaderAction.start());
                const response = await ServiceFactory.GetDocumentsPageService().getUploadedSasLink(clientId, fileName);
                if (response) {
                    let displayDownloadFile = new DisplayDownloadFile();
                    response.data.sas && displayDownloadFile.directDownload(response.data.sas, fileName)
                    dispatch(loaderAction.stop());
                }

            } catch (error) {
                console.log(error);
                dispatch(loaderAction.stop());
            }
        },

    getUploadedDocumentByStream:
        (clientId: string, fileName: string, successCallback: (resp: any) => void) => async (dispatch: any) => {
            try {
                await ServiceFactory.GetDocumentsPageService()
                    .getUploadedDocumentStream(clientId, fileName)
                    .then((response: any) => {
                        successCallback && successCallback(response.data);
                    });
            } catch (error) {
                console.log(error);
            }
        },
    getBookmarks: (clientId: string, sourceDocumentEnabled: boolean) => async (dispatch: any) => {
        try {
            dispatch(loaderAction.start());
            const data = await ServiceFactory.GetDocumentsPageService().getBookmarks(
                clientId,
                sourceDocumentEnabled ? false : true
            );
            dispatch({ type: documentPageTypes.getBookmarks, payload: data.data });
            dispatch(loaderAction.stop());
        } catch (error) {
            console.log(error);
        }
    },

    addUploadedDocument:
        (
            clientId: string,
            uploadedDocument: UploadedDocument,
            successCallback?: () => void,
            failureCallback?: () => void,
            failureCallbackOnConflict?: () => void

        ) =>
            async (dispatch: any) => {
                try {
                    const response: AxiosResponse<CustomResponse<any>> = await ServiceFactory.GetDocumentsPageService().addUploadedDocument(
                        clientId,
                        uploadedDocument
                    );
                    if (response) {
                        successCallback && successCallback();
                    }
                } catch (error: any) {
                    if (error.response?.status === 423) {
                        dispatch(loaderAction.stop());
                        failureCallbackOnConflict && failureCallbackOnConflict();
                    }
                    else {
                        console.log(error);
                        failureCallback && failureCallback();
                    }
                }
            },

    getUploadedDocumentsByFormId: (clientId: string, formId: number) => async (dispatch: any) => {
        try {
            dispatch(loaderAction.start());
            const response = await ServiceFactory.GetDocumentsPageService().getUploadedDocumentsByFormId(
                clientId,
                formId
            );
            dispatch({ type: documentPageTypes.getUploadedDocumentsByFormId, payload: response.data });
            dispatch(loaderAction.stop());
        } catch (error) {
            console.log(error);
            dispatch(loaderAction.stop());
        }
    },

    getUploadedDocuments: (clientId: string) => async (dispatch: any) => {
        try {
            dispatch(loaderAction.start());
            const response = await ServiceFactory.GetDocumentsPageService().getUploadedDocuments(clientId);
            dispatch({ type: documentPageTypes.getUploadedDocuments, payload: response.data });
            dispatch(loaderAction.stop());
        } catch (error) {
            console.log(error);
            dispatch(loaderAction.stop());
        }
    },

    completeDocumentUpload:
        (clientId: string, deletedFiles: string, successCallback?: () => void) => (dispatch: any) => {
            dispatch(loaderAction.start());
            ServiceFactory.GetDocumentsPageService()
                .completeDocumentUpload(clientId, deletedFiles)
                .then((response: AxiosResponse<any>) => {
                    successCallback && successCallback();
                    dispatch(loaderAction.stop());
                })
                .catch((error: any) => {
                    console.log(error);
                    dispatch(loaderAction.stop());
                });
        },

    setFormId: (formId: number, fileName: string) => (dispatch: any) => {
        dispatch({ type: viewDocumentsPageTypes.getFormIdAndBookmark, payload: [formId, fileName] });
    },

    deleteUploadedDocument: (
        clientId: string,
        id: number,
        successCallback?: () => void,
        failureCallbackOnConflict?: () => void) => async (dispatch: any) => {
            try {
                dispatch(loaderAction.start());
                const response: AxiosResponse<CustomResponse<any>> = await ServiceFactory.GetDocumentsPageService().deleteUploadedDocument(clientId, id);

                if (response) {
                    if (response.data.statusCode == ConflictStatusCode) {
                        dispatch({ type: actionTypes.UPLOADED_DOCUMENT_ERROR, errorMessage: ConflictErrorMessage, isError: true });

                        dispatch(actionCreators.setUploadedDocumentCompleted());

                        dispatch(actionCreators.requestUploadedDocumentStatus(clientId));

                        dispatch(loaderAction.stop());

                        return;
                    }

                    successCallback && successCallback();
                    dispatch(loaderAction.stop());
                }
                return;
            } catch (error: any) {
                if (error.response?.status === 423) {
                    dispatch(loaderAction.stop());
                    failureCallbackOnConflict && failureCallbackOnConflict();
                }
                else {
                    console.log(error);
                    dispatch(loaderAction.stop());
                }
            }
        },

    getDeletedDocuments: (fileName: string) => (dispatch: any) => {
        dispatch({ type: viewDocumentsPageTypes.getDeletedDocuments, payload: fileName })
    },

    requestUploadedDocuments: (clientId: string, forceRefresh: boolean) => { },

    onUploadedDocumentModification: () => { }
};
