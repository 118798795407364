import * as React from 'react';
import PdfViewer from 'awesome-pdf-viewer';
import ControlBase, {
    CheckBox, CheckBoxData, ControlBaseProps, ControlData, ControlRole, 
    LabelData, RadioButton, RadioButtonData, SignatureData, SignatureMode, TextBoxData } 
    from 'awesome-pdf-viewer/dist/Controls/ControlBase';
import { LabelControl } from 'awesome-pdf-viewer/dist/Controls/LabelControl';
import { SignatureControl, SignatureControlProps } from 'awesome-pdf-viewer/dist/Controls/SignatureControl';
import { CheckBoxGroupControl, CheckBoxGroupControlProps } from 'awesome-pdf-viewer/dist/Controls/CheckBox/CheckBoxGroupControl';
import { TextBoxControl, TextBoxControlProps } from 'awesome-pdf-viewer/dist/Controls/TextBoxControl';
import ControlLayer from 'awesome-pdf-viewer/dist/Layers/ControlLayer';
import Header from 'awesome-pdf-viewer/dist/layout/Header';
import { PageProperties, PageSize } from 'awesome-pdf-viewer/dist/layout/LayoutBase';
import Main from 'awesome-pdf-viewer/dist/layout/Main';
import ViewPanel from 'awesome-pdf-viewer/dist/layout/ViewPanel';
import CustomOptions from 'awesome-pdf-viewer/dist/toolbar/CustomOptions';
import Pagination from 'awesome-pdf-viewer/dist/toolbar/Pagination';
import Toolbar from 'awesome-pdf-viewer/dist/toolbar/Toolbar';
import Zoom from 'awesome-pdf-viewer/dist/toolbar/Zoom';
import RadioButtonControl, { RadioButtonControlProps } from 'awesome-pdf-viewer/dist/Controls/RadioButtonControl/RadioButtonControl';
import { PdfSource } from 'awesome-pdf-viewer/dist/viewer/ViewerBase';
import { ELDocControlState, OrganizerMetadata, SignerDocumentModel } from 'models/organizer/organizer';
import { IElDocument, IElForm } from 'models/organizer/organizer';
import { container, TYPES } from "../../../../../core/startup";
import { IDateUtilities } from "../../../../../core/utilities";
import * as Controls from "./../../../../../models/esign/controls";
import { ViewerHelper } from "./../../../../../models";
import { AdditionalEsignAdjustments, EngagementLetterConstants } from "./../../../../../common";
import { UploadMethod } from "models/common/enums";

export interface ViewProps {
    clientId: string;
    controlsFound?: boolean;
    documentUrl: string;
    onNavigationFinish(): void;
    startNavigationOnDocumentLoad?: boolean;
    hideNavigationControl: boolean;
    elForms: IElForm[];
    finishTarget?: string;
    onPageChanging(pageProperties: PageProperties): void;
    uploadMethod: UploadMethod;
    setNavigationStatus(show: boolean): void;
    pageNo: number;
    EngagementFiledocuments: SignerDocumentModel;
    onAddControlData(controlData: ControlData, controlProps: ControlBaseProps): void;
    document: IElDocument;
    signCompleted: boolean;
    isReadMode: boolean;
    organizerMetadata: OrganizerMetadata
    controlStatus: ELDocControlState;
}

export interface ViewState {
    hideStartNavigationControl: boolean;
    signatureData: SignatureData | undefined;
    isDocumentLoaded: boolean;
}

const DateUtilities = container.get<IDateUtilities>(TYPES.IDateUtilities);

export class Viewer extends React.Component<ViewProps, ViewState> {

    private _viewPanel: any;
    private _controlLayer: any;
    public _controlList: any[] = [];
    private _toolbar: any;
    private _controlDisplayPanel: any;
    private _bookmarkPanel: any;

    constructor(props: any) {
        super(props);
        this.state = {
            hideStartNavigationControl: this.props.hideNavigationControl != undefined ? this.props.hideNavigationControl : false,
            signatureData: {
                name: "",
                signature: undefined,
                signatureMode: SignatureMode.Type
            },
            isDocumentLoaded: false
        }
    }

    componentDidMount() {
        this.setReferences();
    }

    setReferences() {
        this._toolbar && this._toolbar.setViewerReference(this._viewPanel);
        this._viewPanel && this._viewPanel.setToolbarReference(this._toolbar);
        this._viewPanel && this._viewPanel.setControlsReference(this._controlList);
        this._viewPanel && this._viewPanel.setControlLayerReference(this._controlLayer);
        this._controlLayer && this._controlLayer.setViewerReference(this._viewPanel);
        this._controlLayer && this._controlLayer.setControlsReference(this._controlList);
        this._controlLayer && this._controlLayer.setcontrolDisplayPanelReference(this._controlDisplayPanel);
        this._controlDisplayPanel && this._controlDisplayPanel.setControlsReference(this._controlList);
        this._controlDisplayPanel && this._controlDisplayPanel.setViewerReference(this._viewPanel);
        this._viewPanel.setBookmarkPanelReference(this._bookmarkPanel);
    }

    componentDidUpdate() {
        this.setReferences();
        this.updateSignatureData();
    }

    componentWillReceiveProps(nextProps: ViewProps, nextState: ViewState) {
        if(nextProps.document.fileGuid != this.props.document.fileGuid && this.state.isDocumentLoaded)
        {
            this.setState({ isDocumentLoaded: false }); 
        }
    }

    updateSignatureData = () => {
        const clientName = this.props.organizerMetadata?.clientName;
        const signatureData = this.state.signatureData;
        if (signatureData?.name === "" && clientName && (clientName !== signatureData?.name)) {
            signatureData.name = clientName;
            this.setState({ signatureData: signatureData });
        }
    }

    private setAllSignatureControlValueToSame = (signatureData: SignatureData, controlProps: SignatureControlProps) => 
    {
        const _self = this;
        this.setState({ signatureData: signatureData }, () => {

            this._controlList.filter(x => x != null).forEach(function (item) {

                if (item && item.props.data && item.props.data.controlType == 1 && item.isSigned()) { // signature control
                    item.setControlData({
                        name: signatureData.name,
                        signature: signatureData.signature,
                        signatureMode: signatureData.signatureMode
                    });
                    _self.props.onAddControlData(signatureData, controlProps);
                }
            });
        });
    }

    setSignature = (controlProps: any) => {
            const { signatureData } = this.state;
            const control = this._controlList.find(x => x && x.props.id == controlProps.id);
            control && control.setControlData({ name: signatureData?.name, signature: signatureData?.signature, signatureMode: signatureData?.signatureMode });
            if (!signatureData?.signature) {
                control && control.openSignatureModal();
            }
            else
                this.props.onAddControlData(signatureData, controlProps);
    }

    private setAllTextControlValueToSame = (textBoxData: TextBoxData, controlPops: TextBoxControlProps) => 
    {
        const _self = this;
        _self._controlList.filter(x => x != null).forEach(function (item: any) {


            if (item && item.props.data && item.props.data.controlType == controlPops.data.controlType) {
                item.setControlData(textBoxData);
                _self.props.onAddControlData(textBoxData, item.props);
            }
        });
    }

    private setTextControlValue = (textBoxData: TextBoxData, controlPops: TextBoxControlProps) => {

        this.props.onAddControlData(textBoxData, controlPops);
    }

    private getIsRequired = (required: boolean) => {
        return !!(this.props.isReadMode === true ? false : required);
    }

    private createControls = () => {
        const _self = this;
        let controlCollection: any[] = [];
        this._controlList = [];
        const { isDocumentLoaded } = this.state;

        if (!isDocumentLoaded)
            {
                return this._controlList;
            }

        this.props.elForms.forEach((form) => {
            const pageSize: PageSize = _self._viewPanel.getPageSize(form.pageNo, 1);
            form.controls.forEach((control) => {
                _self.createSignatureControls(control, controlCollection, form.pageNo, pageSize);
            });
        })
        return controlCollection;
    }

    createSignatureControls = (control: any, controlCollection: any[], pageNo: number, pageSize: PageSize) => {
        const _self = this;
        if (pageSize.height > 0) {
            const pdfViewerTop  = ControlBase.getPdfViewerControlTopPosition(pageSize.height, control.boundingRectangle.top) - AdditionalEsignAdjustments.PdfViewerTopAdjustment;
            const pdfViewerLeft = ControlBase.getPdfViewerControlLeftPosition(control.boundingRectangle.left);
            const radioAdjustMent :number = 5;
            const controlData: any | undefined = control.data;
            const isRequiredButtonShowForTextBoxControl = control.required && controlData?.text === "" ? true : false;

            if (control.controlType == Controls.ControlType.Signature) { // signature
                if (control.controlRole != ControlRole.Ero) {
                    const signatureData: any | undefined = controlData && SignatureData.create(
                        controlData.name,
                        controlData.uri,
                        controlData.signatureMode);

                    controlCollection.push(<SignatureControl
                        isRequired={_self.getIsRequired(control.required)}
                        ref={(ref) => _self._controlList.push(ref)}
                        page={pageNo}
                        id={ViewerHelper.ControlIdPrefix + control.id}
                        key={ViewerHelper.ControlKeyPrefix + control.id}
                        name={"Signature"}
                        helptext={control.tooltip}
                        onAddSignature={_self.setAllSignatureControlValueToSame}
                        top={pdfViewerTop} left={pdfViewerLeft} width={99} height={20}
                        data={{ controlType: 1, controlId: control.id }}
                        disabled={true} onClick={_self.setSignature} signatureData={signatureData} />);
                }
            }
            else if (control.controlType == Controls.ControlType.Textbox) {
                if (control.dataType == 8) { // initial
                    controlCollection.push(<TextBoxControl maxLength={60} ref={(ref) => _self._controlList.push(ref)} page={pageNo}
                        id={ViewerHelper.ControlIdPrefix + control.id}
                        isRequired={_self.getIsRequired(control.required)}
                        key={ViewerHelper.ControlKeyPrefix + control.id}
                        name={"Initial"}
                        placeholder={"Initials"}
                        data={{ controlType: control.dataType, controlId: control.id }}
                        helptext={"Initials"}
                        textboxData={TextBoxData.create(controlData?.text == undefined ? "" : controlData?.text)}
                        top={pdfViewerTop}
                        left={pdfViewerLeft}
                        width={250}
                        height={20}
                        onChange={_self.setAllTextControlValueToSame}
                        showValidationTooltip={isRequiredButtonShowForTextBoxControl}
                        />);
                }
                else if (control.dataType == 9) { // Email
                    controlCollection.push(<TextBoxControl maxLength={60} ref={(ref) => _self._controlList.push(ref)} page={pageNo}
                        id={ViewerHelper.ControlIdPrefix + control.id}
                        isRequired={_self.getIsRequired(control.required)}
                        key={ViewerHelper.ControlKeyPrefix + control.id}
                        name={"Email"}
                        placeholder={"Email"}
                        data={{ controlType: control.dataType, controlId: control.id }}
                        helptext={"Email"}
                        textboxData={TextBoxData.create(controlData?.text == undefined ? "" : controlData?.text)}
                        top={pdfViewerTop}
                        left={pdfViewerLeft}
                        width={200}
                        height={20}
                        onChange={_self.setTextControlValue}
                        showValidationTooltip={isRequiredButtonShowForTextBoxControl}
                        />);
                }
                else if (control.dataType == 7) { // name
                    controlCollection.push(<TextBoxControl maxLength={60} ref={(ref) => _self._controlList.push(ref)} page={pageNo}
                        id={ViewerHelper.ControlIdPrefix + control.id}
                        isRequired={_self.getIsRequired(control.required)}
                        key={ViewerHelper.ControlKeyPrefix + control.id}
                        name={"Name"}
                        placeholder={"Name"}
                        data={{ controlType: control.dataType, controlId: control.id }}
                        helptext={"Name"}
                        textboxData={TextBoxData.create(controlData?.text == undefined ? "" : controlData?.text)}
                        top={pdfViewerTop}
                        left={pdfViewerLeft}
                        width={200}
                        height={20}
                        onChange={_self.setAllTextControlValueToSame}
                        showValidationTooltip={isRequiredButtonShowForTextBoxControl}
                        />);
                }
                else { // custom textbox
                    controlCollection.push(<TextBoxControl maxLength={60} ref={(ref) => _self._controlList.push(ref)} page={pageNo}
                        id={ViewerHelper.ControlIdPrefix + control.id}
                        key={ViewerHelper.ControlKeyPrefix + control.id}
                        name={"Text"}
                        isRequired={_self.getIsRequired(control.required)}
                        placeholder={control.tooltip}
                        data={{ controlType: 6, controlId: control.id }}
                        helptext={control.tooltip}
                        textboxData={TextBoxData.create(controlData?.text == undefined ? "" : controlData?.text)}
                        top={pdfViewerTop}
                        left={pdfViewerLeft}
                        width={200}
                        height={20}
                        onChange={_self.setTextControlValue}
                        showValidationTooltip={isRequiredButtonShowForTextBoxControl}
                        />);
                }
            }
            else if (control.controlType == Controls.ControlType.Date) {
                const labelData: LabelData = LabelData.create(DateUtilities.getCurrentDate());
                controlCollection.push(<LabelControl skipNavigation={true} ref={(ref) => _self._controlList.push(ref)}
                    page={pageNo}
                    value={labelData}
                    id={ViewerHelper.ControlIdPrefix + control.id}
                    key={ViewerHelper.ControlKeyPrefix + control.id}
                    name={"Current Date"}
                    helptext={control.tooltip} data={{ controlType: 3, controlId: control.id }}
                    top={pdfViewerTop} left={pdfViewerLeft} width={99} height={20}
                    />);
            }
            else if (control.controlType == Controls.ControlType.SignatureCheckBoxControl) {
                let isAnyRequiredCheckboxClicked = false;
                let isRequiredButtonShowForCheckboxControl = false;
                if (control.data !== undefined) {
                let tempCheckBoxData = [...control.data];
                isAnyRequiredCheckboxClicked = tempCheckBoxData.filter(x => x.checked == true).length > 0;
                }
                if (control.required) {
                isRequiredButtonShowForCheckboxControl = control.data === undefined ? false : !isAnyRequiredCheckboxClicked;
                }   
                let controlItems = _self.getCheckBoxControlItems(control);
                const minTop = Math.min.apply(Math, controlItems.map(function (control) { return control.top; }));
                const minLeft = Math.min.apply(Math, controlItems.map(function (control) { return control.left; }));
                let checkBoxControlItems = [...controlItems];
                const controlItem = checkBoxControlItems[checkBoxControlItems.findIndex(x => x.top == minTop)];
                
                checkBoxControlItems.forEach(function (checkBox: any) {
                    checkBox.top = checkBox.top - minTop;
                    checkBox.left = checkBox.left - minLeft + radioAdjustMent;
                });

                controlCollection.push(<CheckBoxGroupControl
                    id={ViewerHelper.ControlIdPrefix + control.id}
                    key={ViewerHelper.ControlKeyPrefix + control.id}
                    ref={(ref) => { _self._controlList.push(ref) }}
                    page={pageNo}
                    isRequired={_self.getIsRequired(control.required)}
                    width={controlItem.left + 30}
                    height={15}
                    left={pdfViewerLeft + minLeft - radioAdjustMent}
                    top={pdfViewerTop + minTop +  AdditionalEsignAdjustments.CheckBoxTopAdjustment}
                    helptext={control.tooltip}
                    name={"Options"}
                    data={{ controlType: Controls.ControlType.SignatureCheckBoxControl, controlId: control.id }}
                    items={checkBoxControlItems}
                    onChange={_self.updateCheckBoxControlValue}
                    showValidationTooltip={isRequiredButtonShowForCheckboxControl}
                    />);
            }
            else if (control.controlType == Controls.ControlType.SignatureRadioButtonControl) {
                let isRadiobuttonClicked = false;
                let isRequiredButtonShowForRadioButtonControl = false;
                if (control.data !== undefined) {
                    isRadiobuttonClicked = control.data.checked;
                }
                if (control.required) {
                    isRequiredButtonShowForRadioButtonControl = control?.data === undefined ? false : !isRadiobuttonClicked;
                }
                let controlItems = _self.getRadioButtonControlItems(control);
                const minTop = Math.min.apply(Math, controlItems.map(function (control) { return control.top; }));
                const minLeft = Math.min.apply(Math, controlItems.map(function (control) { return control.left; }));
                let radioButtonControlItems = [...controlItems];
                const controlItem = radioButtonControlItems[radioButtonControlItems.findIndex(x => x.top == minTop)];
                radioButtonControlItems.forEach(function (checkBox: any) {
                    checkBox.top = checkBox.top - minTop;
                    checkBox.left = checkBox.left - (minLeft + radioAdjustMent);
                });
                controlCollection.push(<RadioButtonControl
                    id={ViewerHelper.ControlIdPrefix + control.id}
                    key={ViewerHelper.ControlKeyPrefix + control.id}
                    ref={(ref) => { _self._controlList.push(ref) }}
                    page={pageNo}
                    isRequired={_self.getIsRequired(control.required)}
                    width={controlItem.left + 30}
                    height={15}
                    left={pdfViewerLeft + (minLeft - radioAdjustMent)}
                    top={pdfViewerTop + minTop  + AdditionalEsignAdjustments.CheckBoxTopAdjustment}
                    helptext={control.tooltip}
                    name={"Options"}
                    data={{ controlType: Controls.ControlType.SignatureRadioButtonControl, controlId: control.id }}
                    items={radioButtonControlItems}
                    onChange={_self.updateRadioButtonControlValue}
                    showValidationTooltip={isRequiredButtonShowForRadioButtonControl}
                    />);
            }
            else {
                console.warn("control not implemented !!");
            }
        }
    }
    private updateCheckBoxControlValue = (checkBoxes: CheckBox[], controlPops: CheckBoxGroupControlProps) => {
        this.props.onAddControlData(checkBoxes, controlPops);
    }

    private updateRadioButtonControlValue = (radioButtons: RadioButton[], controlPops: RadioButtonControlProps) => {
        this.props.onAddControlData(radioButtons, controlPops);
    }

    private getCheckBoxControlItems = (control: Controls.CheckBoxControl) => {
        const controlData: Controls.IControlData | undefined = control.data;
        const checkBoxResult = controlData as Controls.IChoosableControlResult[];

        let checkBoxes: CheckBox[] = [];
        control.items.forEach(function (checkBox: any) {

            let checked: boolean = false;
            let result = checkBoxResult?.filter(x => x.id === checkBox.id);
            if (result && result.length > 0) {
                checked = result[0].checked;
            }

            const tmpCheckBox: CheckBox = CheckBox.create(checkBox.name,
                checkBox.top,
                checkBox.left,
                checkBox.width,
                checkBox.height,
                CheckBoxData.create(checked));

            tmpCheckBox.id = checkBox.id;

            checkBoxes.push(tmpCheckBox);
        });

        return checkBoxes;
    }

    private getRadioButtonControlItems = (control: Controls.RadioButtonControl) => {
        const controlData: Controls.IControlData | undefined = control.data;
        const radioButtonResult = controlData as Controls.IChoosableControlResult;
        const radioButtonData: Controls.ChoosableControlResult | undefined = controlData && Controls.ChoosableControlResult.create(radioButtonResult.id, radioButtonResult.checked,);

        let radioButtons: RadioButton[] = [];

        control.items.forEach(function (radio: any) {

            let selected: boolean = false;
            if (radio.id.toString() == radioButtonData?.id) {
                selected = true;
            }

            const tmpRadioButtonData = RadioButtonData.create(selected, "");
            const tmpRadioButton: RadioButton = RadioButton.create(radio.name,
                radio.top,
                radio.left,
                radio.width,
                radio.height,
                tmpRadioButtonData);

            tmpRadioButton.id = radio.id;

            radioButtons.push(tmpRadioButton);
        });

        return radioButtons;
    }

    private startNavigation = () => {
        this.setState({ isDocumentLoaded: true }, () => {
        let _self = this;
        if (_self.props.startNavigationOnDocumentLoad && _self.props.hideNavigationControl) {
            const page: IElForm | undefined = this.props.elForms.find((x: IElForm) => x.controls.length > 0);
            page && _self._viewPanel.gotoPage(page.pageNo);
        }
        //some delay to load the pdf pages with controls

        setTimeout(
            function () {

                _self.props.startNavigationOnDocumentLoad && _self.props.hideNavigationControl && _self._controlLayer.startNavigation();

            }.bind(this),
            2000);
        });
    }

    onNavigationBegin = () => {
        this.setState({ hideStartNavigationControl: true })
        this.props.setNavigationStatus((true));
        const controlsFound = this._controlList && this._controlList.filter((x) => x?.isValid && x?.isValid() == false).length;
        if (controlsFound <= 0) {//if no controls found navigate to the first document where control present
            this.props.onNavigationFinish();
        }
    }
    OnDownloadFile = (Url: string, FileName?: string, isReadMode?: boolean) => {
            var a = document.createElement("a");
            a.href = Url;
            a.setAttribute("download", FileName ? FileName : "file.pdf");
            a.click();
        }

    public render() {

        const { hideStartNavigationControl } = this.state;
        const { documentUrl, controlStatus } = this.props;
        let navigationFinishTarget: any = undefined;
        if (this.props.finishTarget) {
            var targetId = controlStatus === ELDocControlState.NoControles ? "btnSkip" : "btnFinish"
            navigationFinishTarget = {
                targetId: targetId,
                text: "Finish",
            };
        }
        var pdfSource = PdfSource.createFromUrl(this.props.documentUrl);

        return (<PdfViewer id={"awesome-pdf-viewer"} readMode={this.props.isReadMode} >
            <Header>
                <Toolbar ref={(ref: any) => this._toolbar = ref}
                    hideRightPanel={true}
                    hideLeftPanel={true}
                    hideReadOnly={true}
                    showDownload={true}
                    downloadFileName={EngagementLetterConstants.DownloadEngagementLetterFileName}
                    onDownload={() => this.OnDownloadFile(
                        documentUrl,
                        EngagementLetterConstants.DownloadEngagementLetterFileName,
                    )}
                >
                    <Pagination />
                    <Zoom />
                    <CustomOptions />
                </Toolbar>

            </Header>

            <Main>
                <ControlLayer ref={(ref: any) => this._controlLayer = ref}
                    useDefaultNavigationStartControl={!(hideStartNavigationControl)}
                    navigationFinishTarget={navigationFinishTarget}
                    onNavigationComplete={this.props.onNavigationFinish}
                    onNavigationBegin={this.onNavigationBegin}>
                    {this.createControls()}
                </ControlLayer>

                <ViewPanel ref={(ref: any) => this._viewPanel = ref}
                    onDocumentLoad={this.startNavigation}
                    url={this.props.documentUrl}
                    pdfSource={pdfSource}
                    onPageChanging={this.props.onPageChanging}
                    disableTextLayer={true}
                >
                </ViewPanel>
            </Main>
        </PdfViewer>);
    }
}
