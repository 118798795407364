import { documentClientTypes } from "../../actionTypes";
import { IClientInfo, ClientInfo } from "../../../models/index";
import { Action } from "redux";

const initialState: IClientInfo = ClientInfo.createNullObject();

export const documentClientsMetaData = (state = initialState, action: any) => {
    switch (action.type) {
        case documentClientTypes.documentClientData:
            return action.payload;
        default:
            return state;
    }
};
