import "reflect-metadata";
import { Container } from "inversify";
import { ILocalStore } from "../../utilities";
import { TYPES } from "../inversify/types";
import { UtilityFactory, IDateUtilities, IFileUtilities, IUtilities } from "../../utilities";

const container = new Container();

container.bind<ILocalStore>(TYPES.ILocalStore).toConstantValue(new UtilityFactory().getLocalStore());
container.bind<IDateUtilities>(TYPES.IDateUtilities).toConstantValue(new UtilityFactory().getDateUtility());
container.bind<IFileUtilities>(TYPES.IFileUtilities).toConstantValue(new UtilityFactory().getFileUtility());
container.bind<IUtilities>(TYPES.IUtilities).toConstantValue(new UtilityFactory().getUtility());

export { container };
