import { userTypes } from "../actionTypes";

const initialState: any = [];

export const users = (state = initialState, action: any) => {
    switch (action.type) {
        case userTypes.list:
            return action.payload;

        default:
            return state;
    }
};
