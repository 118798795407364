import { GroupType,FormType,UploadMethod} from '../common/enums';

export interface ISignerDocumentModel{
    id: number;
    documentGuid: string;
    organizerClients: [];
    formGroup: FormGroup[];
    documentSettings: any;
    documentUrl: string;
    uploadMethod: UploadMethod;
    additionalEsign: [];
    organizerFormGroupUrl:string;
    engagementLetterFormGroupUrl:string;
    data:any
}

export class SignerDocumentModel implements ISignerDocumentModel {

    id: number;
    documentGuid: string;
    organizerClients: [];
    formGroup: FormGroup[];
    documentSettings: any;
    documentUrl: string;
    organizerFormGroupUrl:string;
    engagementLetterFormGroupUrl:string;
    uploadMethod: UploadMethod;
    additionalEsign:[];
    data: any;

    constructor(id: number,
        documentGuid: string,
        organizerClients: [],
        formGroup: FormGroup[],
        documentSettings: any,
        documentUrl: string, 
        organizerFormGroupUrl:string,
        engagementLetterFormGroupUrl:string,
        uploadMethod: UploadMethod,
        data: any,
        additionalEsign:[]) 
        {
        this.id = id;
        this.documentGuid = documentGuid;
        this.organizerClients = organizerClients;
        this.formGroup = formGroup;
        this.documentSettings = documentSettings;
        this.documentUrl = documentUrl;
        this.organizerFormGroupUrl = organizerFormGroupUrl;
        this.engagementLetterFormGroupUrl = engagementLetterFormGroupUrl;
        this.uploadMethod = uploadMethod;
        this.additionalEsign=additionalEsign;
        this.data =  data
    }
    public static createNullObject(): ISignerDocumentModel {
        return new SignerDocumentModel(0, "", [], [{ type: GroupType.None, forms: [] }], {}, "", 
        "","",UploadMethod.None,{},[]);
    }

}

export interface IForm{
    formType: FormType;
    pageNo: number;
    formName: string;
    bookmark: string;
    formData: IFormData;
    formGroupId: string;
    formId: number;
    formGuid: string;
    individualFilePageNo?: number;
    message: string;
}

export class Form  implements IForm{

    formType: FormType;
    pageNo: number;
    formName: string;
    bookmark: string;
    formData: IFormData;
    formGroupId: string;
    formId: number;
    formGuid: string;
    individualFilePageNo?: number;
    message: string;
    constructor(formType: FormType,
        pageNo: number,
        formName: string,
        bookmark: string,
        formData: IFormData,
        formGroupId: string,
        formId: number,
        formGuid: string,
        message: string,
        individualFilePageNo?: number,
    ) {

        this.formType = formType;
        this.pageNo = pageNo;
        this.formName = formName;
        this.message = message;
        this.bookmark = bookmark;
        this.formData = formData;
        this.formGroupId = formGroupId;
        this.formId = formId;
        this.formGuid = formGuid;
        this.individualFilePageNo = individualFilePageNo;
    }


    public static create(formType: FormType, formGuid: string, formData: IFormData): IForm {
        return new Form(formType, 0, "", "", formData, "", 0, formGuid, "", 0);
    }
}

export class FormGroup {
    type: GroupType;
    forms: IForm[];

    constructor(groupType: GroupType,
        forms: IForm[]) {
        this.type = groupType;
        this.forms = forms;
    }
    public static createNullObject(): FormGroup {
        return new FormGroup(GroupType.None, []);
    }
}

export interface IFormData {

}
