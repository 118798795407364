import React, { useEffect } from "react";
import { Header } from "../../layout/header";
import { coverPageAction, HeaderInfoAction } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { ErrorStatus, IBaseClientInfo, IHeaderInfoViewModel, OtpMode } from "../../../models";
import { Toaster } from "../../common";
import { Shimmer } from "../../common";

export const CoverPage: React.FC<{}> = () => {
    const dispatch = useDispatch();
    const params: any = useParams();
    const history = useHistory();

    const { headerInfoMetaData } = useSelector((state: { headerInfoMetaData: IHeaderInfoViewModel }) => state);

    const { baseClientInfoMetaData } = useSelector((state: { baseClientInfoMetaData: IBaseClientInfo }) => state);

    useEffect(() => {
        dispatch(coverPageAction.getClientInfo(params.id, errorCallback));

        dispatch(HeaderInfoAction.getHeaderInfo(params.id, errorCallback));

    }, [dispatch]);

    const errorCallback = (errorStatus: ErrorStatus) => {
        history.push(`/error/${errorStatus}`);
    }
    
    const handleRedirect = (otpmode: OtpMode) => {
        switch (otpmode) {
            case OtpMode.Text:
                history.push("/authentication/mobileOTP/" + params.id);
                break;
            default:
                history.push("/authentication/otp/" + params.id);
                break;
        }
    };

    let navigate = () => {
        dispatch(coverPageAction.getAuthenticationType(params.id, handleRedirect, errorCallback));
    };

    return (
        <>
            {
                baseClientInfoMetaData.isLoading ? <Shimmer height={'100vh'} /> : <>
                    <Toaster />
                    <Header
                        companyName={headerInfoMetaData.companyName}
                companyLogo={headerInfoMetaData.companyWhiteLogoPath}
                    ></Header>

            <div data-testid="welcomePage" className="welcome-page">
                        <div data-test-auto="NYG6C9FLML943538RXC2D9XDW8" className="col-sm-12 col-xs-12 welcome-page-container">
                            <div style={{ height: "40%" }}>
                                <div className="company-info-container align-img-center">
                                    {baseClientInfoMetaData.logoPath !== "" ? (
                                        <img
                                    data-testid="logo"
                                            data-test-auto="VM1Y507X3KAMT985AC3UCPACMW"
                                            className="prepared-by-img"
                                            src={baseClientInfoMetaData.logoPath}
                                            alt="Company Logo"
                                        />
                                    ) : (
                                        <>
                                            <span
                                                data-test-auto="R3YCTEA0QLM4T6Z3495P4NJ1DR"
                                                className="prepared-by-company-name"
                                            >
                                                {baseClientInfoMetaData.contactAddress.companyName}
                                            </span>
                                            <br />
                                        </>
                                    )}
                                </div>
                            </div>
                            <div style={{ height: "40%" }}>
                                <div className="tax-client-info-container">
                                    <div className="tax-client-info-label">
                                <span data-testid="t-span">T</span>
                                        <br />
                                <span data-testid="o-span">O</span>
                                    </div>

                                    <div className="tax-client-info-text">
                                        <span data-test-auto="JY3NUDQEG0D471NH0XPU52468D" className="tax-year-header">
                                            Tax Organizer
                                        </span>
                                        <h6 data-test-auto="W4GRMP71REY4Z1DV1YG7L6XDLH" className="tax-client-name">
                                            {baseClientInfoMetaData.clientName}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div style={{ height: "20%", textAlign: "center" }}>
                                <button
                                    className="btn btn-default btn-round btn-organizer-continue"
                                    onClick={navigate}
                                    data-test-auto="22DCD9A3-E953-44BB-81AF-3035DE70367F"
                                >
                                    Get Started
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            }

        </>
    );
};
function redirectAuthentication() {
    throw new Error("Function not implemented.");
}

export default CoverPage;
