import axios from "axios";
import { container, TYPES } from "../../../core/startup";
import { ILocalStore } from "../../../core/utilities";
import { LocalStorageConstant, OrganizerConstants, OTPPageConstants } from "../../../common/constants/constant";
import { MessageBox } from "../../../components/common";
import { Logger } from "../../../App";
import { ErrorStatus } from "models";
import config from "../../../config";

const localStorageService = container.get<ILocalStore>(TYPES.ILocalStore);
const BASE_URL = config.apiGateway.url;

axios.interceptors.request.use(
    (req) => {
        return handleRequest(req);
    },
    (err) => {
        return Promise.reject(err);
    }
);

axios.interceptors.response.use(
    (res) => res,
    (error) => handleResponseError(error)
);
function isValidUrl(url:any) {
    return url.startsWith(BASE_URL);
}


function handleRequest(req: any) {
    req.headers = req.headers || {};
    if (req.url && isValidUrl(req.url)) {
        const cleanUrl = req?.url.split("?")[0];
        const id = req.url.substr(cleanUrl.lastIndexOf("/") + 1, 36)
        const token = localStorageService.getItemByUniqueKey(id,LocalStorageConstant.Token);

        req.headers["Authorization"] = "Bearer " + token;
    }
    return req;
}

function handleResponseError(error: any) {
    const data = parseErrorResponseData(error);

    if (error?.response?.status === 410) {
        return handleGoneError(data);
    }

    if (error.message && error.config?.url) {
        const encryptedCode = extractEncryptedCode(error.config.url);

        Logger.trackError(`Request: ${error.config.url} failed due to: ${error.message}`);

        if (error.message.includes("401")) {
            handleUnauthorizedError(encryptedCode);
        } else if (error.message.includes("Network Error") && !encryptedCode) {
            showUnauthorizedMessage();
        }
    }

    return Promise.reject(error);
}

function extractEncryptedCode(url: string): string | null {
    const cleanUrl = url.split("?")[0];
    const uniqueId = cleanUrl.substring(cleanUrl.lastIndexOf("/") + 1, 36);
    return localStorageService.getItemByUniqueKey(uniqueId, LocalStorageConstant.EncryptedClientId);
}

function handleUnauthorizedError(encryptedCode: string | null) {
    if (encryptedCode) {
        clearLocalStorage(encryptedCode);
        redirectToCoverPage(encryptedCode);
    } else {
        showUnauthorizedMessage();
    }
}

function clearLocalStorage(uniqueId: string) {
    localStorageService.removeItemByUniqueKey(uniqueId, LocalStorageConstant.Token);
    localStorageService.removeItemByUniqueKey(uniqueId, LocalStorageConstant.RefreshToken);
    localStorageService.removeItemByUniqueKey(uniqueId, LocalStorageConstant.EncryptedClientId);
}

function redirectToCoverPage(encryptedCode: string) {
    window.location.href = `${OrganizerConstants.CoverPageURL}${encryptedCode}`;
}

function showUnauthorizedMessage() {
    MessageBox.Info(OTPPageConstants.ErrorMessage.Unauthorized);
    setTimeout(() => {
        window.location.href = OrganizerConstants.ErrorPageURL;
    }, 1000);
}

function parseErrorResponseData(error: any): any {
    if (error?.response?.config?.responseType === 'arraybuffer') {
        const textDecoder = new TextDecoder('utf-8');
        const jsonString = textDecoder.decode(new Uint8Array(error?.response?.data));
        return JSON.parse(jsonString);
    }
    return error?.response?.data;
}

function handleGoneError(data: any): Promise<any> {
    if ([ErrorStatus.ClosedByFirm, ErrorStatus.OrganizerDeleted].includes(data?.errorCode)) {
        window.location.href = `${OrganizerConstants.ErrorPage}${data?.errorCode}`;
        return Promise.reject(data?.message);
    }
    return Promise.resolve();
}

