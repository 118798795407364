import { injectable } from "inversify";
import "reflect-metadata";

export interface ILocalStore {
    getItem(key: string): any;
    getItemByUniqueKey(uniqueKey: string, itemKey: string): any;
    setItem(key: string, value: any): void;
    setItemByUniqueKey(uniqueKey: string, itemKey: string, value: any): void;
    removeItem(key: string): void;
    removeItemByUniqueKey(uniqueKey: string, itemKey: string): void;
    isExists(key: string): boolean;
    isExistsByUniqueKey(uniqueKey: string, itemKey: string): boolean;
    clear(): void;
}

@injectable()
export class LocalStorageStore implements ILocalStore {
    constructor() {}

    getItem(key: string): any {
        const data: string | null = localStorage.getItem(key);
        if (data !== null) {
            return JSON.parse(data).value;
        }
        return null;
    }

    getItemByUniqueKey(uniqueKey: string, itemKey: string): any {
        const data: string | null = localStorage.getItem(`${uniqueKey}_${itemKey}`);
        if (data !== null) {
            return JSON.parse(data).value;
        }
        return null;
    }
    setItem(key: string, value: any): void {
        localStorage.setItem(key, JSON.stringify({ value }));
    }
    setItemByUniqueKey(uniqueKey: string, itemKey: string, value: any): void {
        localStorage.setItem(`${uniqueKey}_${itemKey}`, JSON.stringify({ value }));
    }
    removeItem(key: string): void {
        localStorage.removeItem(key);
    }
    removeItemByUniqueKey(uniqueKey: string, itemKey: string): void {
        localStorage.removeItem(`${uniqueKey}_${itemKey}`);
    }
    isExists(key: string): boolean {
        return localStorage.getItem(key) === null ? false : true;
    }
    isExistsByUniqueKey(uniqueKey: string, itemKey: string): boolean {
        return localStorage.getItem(`${uniqueKey}_${itemKey}`) === null ? false : true;
    }
    clear(): void {
        localStorage.clear();
    }
}
